import type { Company, Role, RolePermissionName } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';
import type { AccessStatus } from 'common/containers/AccessContainer';

export type RoutePermissionsMap = Record<string, RolePermissionName[]>;

const createRoutePermissionsMap = <T extends string>(map: Record<T, RolePermissionName[]>) => map;

const findViewerRole = (company: Company, viewer: Viewer): Role | null => {
  if (!company.members) {
    return null;
  }

  const companyMember = company.members.find((member) => {
    return member._id === viewer._id;
  });

  if (!companyMember) {
    // could not find viewer in list of company members
    return null;
  }

  const { roleID } = companyMember;
  const role = company.roles.find((role) => {
    return role._id === roleID;
  });

  if (!role) {
    // could not find role for viewer
    return null;
  }

  return role;
};

// Used when gate-keeping a route that has 1 to many permissions required to view it
// We want to know if the viewer has all of the permissions
const hasEveryPermission = (
  permissionKeys: RolePermissionName[],
  company: Company,
  viewer: Viewer
): AccessStatus => {
  const role = findViewerRole(company, viewer);

  if (role === null) {
    return { result: 'error', reason: 'Either not a member of the company or no role assigned' };
  }

  const missingPermissions = permissionKeys.filter(
    (permissionKey) => !role.permissions[permissionKey]
  );

  if (missingPermissions.length > 0) {
    return {
      result: 'failure',
      reason: 'permission',
      data: { missingPermissions },
    };
  }

  return { result: 'success' };
};

// Used when gate-keeping a set of routes that have varying permissions.
// We want to know if the viewer has any of the permissions in the list - if so
// we will allow them to access the route. An example of this is the Admin settings page
// where /board might represent 5 sub-settings pages and thus 5 different permissions
const hasSomePermission = (
  permissionKeys: RolePermissionName[],
  company: Company,
  viewer: Viewer
): AccessStatus => {
  const role = findViewerRole(company, viewer);

  if (role === null) {
    return { result: 'error', reason: 'Either not a member of the company or no role assigned' };
  }

  const missingPermissions = permissionKeys.filter(
    (permissionKey) => !role.permissions[permissionKey]
  );

  // they are missing all the permissions
  if (missingPermissions.length === permissionKeys.length) {
    return {
      result: 'failure',
      reason: 'permission',
      data: { missingPermissions },
    };
  }

  return { result: 'success' };
};

const findMatchingRoute = (
  routePermissionsMap: RoutePermissionsMap,
  company: Company,
  viewer: Viewer
): string | null => {
  for (const [route, permissions] of Object.entries(routePermissionsMap)) {
    if (hasEveryPermission(permissions, company, viewer).result === 'success') {
      return route;
    }
  }

  return null;
};

const AdminBoardSettingsRoutePermissions = createRoutePermissionsMap({
  general: ['customizeBoards', 'manageBoards'],
  'create-form': ['customizeBoards'],
  privacy: ['manageBoardPrivacy'],
  tags: ['manageTags'],
  widget: ['manageBoards'],
  categories: ['manageCategories'],
  'data-import': ['importPosts'],
  'data-export': ['exportData'],
  'smart-replies': ['manageBoards'],
  delete: ['manageBoards'],
});

const AdminChangelogSettingsRoutePermissions = createRoutePermissionsMap({
  labels: ['manageChangelog'],
  privacy: ['manageChangelogPrivacy'],
});

const AdminRoadmapStatusSettingsRoutePermissions = createRoutePermissionsMap({
  'public-view': ['manageCompanyProfile'],
  statuses: ['manageStatuses'],
  archive: ['manageRoadmap'],
});

const AdminTeamSettingsRoutePermissions = createRoutePermissionsMap({
  people: ['manageTeammates'],
  teams: ['manageTeammates'],
  roles: ['manageTeammates'],
});

const AdminNotificationSettingsRoutePermissions = createRoutePermissionsMap({
  types: ['manageEmailSettings'],
  domain: ['manageCustomDomains'],
});

const AdminGeneralSettingsRoutePermissions = createRoutePermissionsMap({
  branding: ['manageCompanyProfile'],
  preferences: ['manageCompanyProfile'],
  delete: ['deleteInstance'],
});

const AdminSecuritySettingsRoutePermissions = createRoutePermissionsMap({
  'email-auth': ['manageAuthSettings'],
  'secure-identify': ['manageAuthSettings'],
  'sso-redirect': ['manageAuthSettings'],
});

const AdminCustomDomainSettingsRoutePermissions: RolePermissionName[] = ['manageCustomDomains'];

const AdminCustomPostFieldsSettingsRoutePermissions = createRoutePermissionsMap({
  'post-fields': ['manageCustomPostFields'],
  'company-fields': ['manageCustomPostFields'],
});

const AdminAPISettingsRoutePermissions: RolePermissionName[] = ['manageAPIKey'];

const AdminBillingSettingsRoutePermissions = createRoutePermissionsMap({
  subscription: ['manageBilling'],
  autopilot: ['manageBilling'],
});

const AdminIntegrationSettingsRoutePermissions = createRoutePermissionsMap({
  all: ['manageIntegrations'],
  active: ['manageIntegrations'],
  autopilot: ['manageIntegrations'],
  feedback: ['manageIntegrations'],
  identify: ['manageIntegrations'],
  'categories-all': ['manageIntegrations'],
  authentication: ['manageIntegrations'],
  automation: ['manageIntegrations'],
  communication: ['manageIntegrations'],
  'data-and-enrichment': ['manageIntegrations'],
  'project-management': ['manageIntegrations'],
  sales: ['manageIntegrations'],
});

const AdminInboxSettingsRouterPermissions: RolePermissionName[] = ['manageQueue'];

const AdminIntegrationsRoutePermissions = createRoutePermissionsMap({
  asana: ['manageIntegrations'],
  azureDevOps: ['manageIntegrations'],
  jira: ['manageIntegrations'],
  github: ['manageIntegrations'],
  clickup: ['manageIntegrations'],
  linear: ['manageIntegrations'],
  freshdesk: ['manageIntegrations'],
  intercom: ['manageIntegrations'],
  zendesk: ['manageIntegrations'],
  salesforce: ['manageIntegrations'],
  hubspot: ['manageIntegrations'],
  slack: ['manageIntegrations'],
  microsoftTeams: ['manageIntegrations'],
  discord: ['manageIntegrations'],
  gong: ['manageIntegrations'],
  helpScout: ['manageIntegrations'],
  azure: ['manageIntegrations'],
  gSuite: ['manageIntegrations'],
  oidc: ['manageIntegrations'],
  okta: ['manageIntegrations'],
  oneLogin: ['manageIntegrations'],
  zapier: ['manageIntegrations'],
  segment: ['manageIntegrations'],
  googleAnalytics: ['manageIntegrations'],
  zoom: ['manageIntegrations'],
});

const RoutePermissions = {
  adminSettings: {
    general: AdminGeneralSettingsRoutePermissions,
    security: AdminSecuritySettingsRoutePermissions,
    customDomain: AdminCustomDomainSettingsRoutePermissions,
    postFields: AdminCustomPostFieldsSettingsRoutePermissions,
    api: AdminAPISettingsRoutePermissions,
    billing: AdminBillingSettingsRoutePermissions,
    integrations: AdminIntegrationSettingsRoutePermissions,
    notifications: AdminNotificationSettingsRoutePermissions,
    roadmap: AdminRoadmapStatusSettingsRoutePermissions,
    changelog: AdminChangelogSettingsRoutePermissions,
    board: AdminBoardSettingsRoutePermissions,
    team: AdminTeamSettingsRoutePermissions,
    inbox: AdminInboxSettingsRouterPermissions,
  },
  integrations: AdminIntegrationsRoutePermissions,
};

const testEveryPermission =
  (permissions: RolePermissionName[]) => (company: Company, viewer: Viewer) =>
    hasEveryPermission(permissions, company, viewer);

export {
  findMatchingRoute,
  hasSomePermission,
  hasEveryPermission,
  RoutePermissions,
  testEveryPermission,
};
