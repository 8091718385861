import React from 'react';

import { ArrowRight } from 'lucide-react';

import Pill from 'common/common/Pill';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ButtonV2 from 'common/ui/ButtonV2';
import { P, Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/_AdminAutopilotZapierIntegrationItem.module.scss';

import ZapierPlatformLogo from 'img/zapier-platform-logo-small.svg';

import type { ZapierIntegrationItem } from './AdminFeedbackDiscoverySettings';

const CreditsPill = ({ credits }: Pick<ZapierIntegrationItem, 'credits'>) => {
  if (typeof credits === 'number') {
    return (
      <Pill className={styles.creditsPill}>
        {credits > 1 ? `${credits} credits / response` : `${credits} credit / response`}
      </Pill>
    );
  }

  return (
    <Pill className={styles.creditsPill}>
      {credits.min}-{credits.max} credits / response
    </Pill>
  );
};

const AdminAutopilotZapierIntegrationItem = ({
  credits,
  logo,
  label,
  url,
}: ZapierIntegrationItem) => {
  return (
    <div className={styles.item}>
      <div className={styles.topContainer}>
        <div className={styles.left}>
          <LazyLoadedImage className={styles.sourceLogo} src={logo} />
          <P variant="bodyMd">
            {label}{' '}
            <Span className={styles.zapierAttribution} variant="bodySm">
              via <LazyLoadedImage className={styles.zapierPlatformLogo} src={ZapierPlatformLogo} />
            </Span>
          </P>
          <CreditsPill credits={credits} />
        </div>
        <div className={styles.right}>
          <ButtonV2
            variant="outlined"
            endIcon={ArrowRight}
            onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
            size="medium">
            Open Zapier
          </ButtonV2>
        </div>
      </div>
    </div>
  );
};

export default AdminAutopilotZapierIntegrationItem;
