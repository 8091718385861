import React, { useCallback, useContext, useEffect, useState } from 'react';

import { SignJWT } from 'jose';
import { type Dispatch, compose } from 'redux';

import { reloadAsanaSettings } from 'common/actions/asanaSettings';
import AJAX from 'common/AJAX';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Dropdown from 'common/Dropdown';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import setCookie from 'common/setCookie';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';
import { dayjs } from 'common/util/dayjsUtils';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';

import Rules from './Rules';

import type { AsanaSettings } from 'common/api/endpoints/asanaSettings';

import 'css/components/subdomain/admin/_AdminAsanaSettings.scss';

type OwnProps = {
  asanaSettings: AsanaSettings;
};

type ConnectProps = {
  reloadAsanaSettings(): void;
};

type Props = OwnProps & ConnectProps;

// For more information, visit: https://developers.asana.com/docs/oauth
const Asana = 'asana';
const AsanaParams = {
  client_id: publicConfig('asanaClientID'),
  redirect_uri: devURL('https://canny.io/asana-redirect'),
  response_type: 'code',
};
const AsanaURL = 'https://app.asana.com/-/oauth_authorize';

const AdminAsanaSettings = (props: Props) => {
  // props
  const { asanaSettings, reloadAsanaSettings } = props;

  // context
  const activeIntegrations = useContext(ActiveIntegrationContext);
  const company = useContext(CompanyContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const viewer = useContext(ViewerContext);

  // state
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [workspaceID, setWorkspaceID] = useState<string | null>(null);
  const [updatingWorkspace, setUpdatingWorkspace] = useState<boolean>(false);
  const [appAuthorization] = useState<boolean>(location?.query?.appAuthorization === 'true');
  const [asanaState, setAsanaState] = useState<string | null>(null);

  // helpers
  const install = useCallback(
    async (code: string) => {
      setError(null);
      setLoading(true);

      const response = await AJAX.post('/api/asana/install', { code });
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        setLoading(false);
        setError(error.message);
        return;
      }

      await reloadAsanaSettings();
      setLoading(false);
    },
    [reloadAsanaSettings]
  );

  const setSelectedWorkspace = (workspaceID: string) => {
    setWorkspaceID(workspaceID);
  };

  const uninstall = async () => {
    setLoading(true);
    setError(null);

    const response = await AJAX.post('/api/asana/uninstall', {});
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setLoading(false);
      setError(error.message);
      return;
    }

    await reloadAsanaSettings();
    setLoading(false);
  };

  const updateWorkspace = async () => {
    setUpdatingWorkspace(true);

    const response = await AJAX.post('/api/asana/setWorkspace', { workspaceID });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setUpdatingWorkspace(false);
      setError(error.message);
      return;
    }

    await reloadAsanaSettings();
    setUpdatingWorkspace(false);
  };

  // effects
  useEffect(() => {
    if (asanaState) {
      return;
    }

    const generateJWT = async () => {
      const stateBody = {
        integration: Asana,
        subdomain: company.subdomain,
        ...(appAuthorization && { appAuthorization }),
        hash: viewer.cannyHash,
      };
      const jwt = await new SignJWT(stateBody)
        .setProtectedHeader({ alg: 'HS256' })
        .setExpirationTime('2h')
        .sign(new TextEncoder().encode(viewer.cannyHash));
      const state = encodeURIComponent(jwt);

      setAsanaState(state);
      setCookie('asana_state_value', state);
    };
    generateJWT();
  }, [appAuthorization, asanaState, company.subdomain, viewer.cannyHash]);

  useEffect(() => {
    const { code } = location.query;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    install(code);
  }, [install, location.query, location.pathname, router]);

  useEffect(() => {
    if (!asanaSettings || !appAuthorization) {
      return;
    }

    const { installation, workspaces } = asanaSettings;
    const hasInstalled = !!installation?.installed && !installation?.lostAccess;

    if (!hasInstalled) {
      return;
    }

    const workspace = workspaces.find((workspace) => workspace.id === installation.workspaceID);

    if (!workspace) {
      return;
    }

    const redirectURL = devURL(`https://canny.io/asana-redirect?appAuthorizationCompleted=true`);
    window.location.replace(redirectURL);
  }, [asanaSettings, appAuthorization]);

  // renderers
  const renderContents = () => {
    if (!asanaSettings) {
      return null;
    }

    const { customFields, installation, rules, workspaces } = asanaSettings;
    const hasInstalled = !!installation?.installed && !installation?.lostAccess;

    if (!company?.integrations?.asana) {
      return <AdminFeatureUpsell feature="asana" />;
    } else if (loading) {
      return renderLoading();
    } else if (!hasInstalled) {
      return renderInstallButton();
    }

    const workspace = workspaces.find((workspace) => workspace.id === installation.workspaceID);
    const workspaceOptions = workspaces.map((workspace) => ({
      name: workspace.id,
      render: workspace.name,
    }));
    const syncStatus = capitalizeFirstLetter(installation.syncStatus);
    const syncExecutedAt = installation.syncExecutedAt
      ? dayjs(installation.syncExecutedAt).format('D/MM/YYYY HH:mm')
      : 'None';

    return (
      <div className="installation">
        <div className="status">
          {workspace ? (
            <div className="text">
              Your Asana integration is installed for {workspace.name} workspace.
            </div>
          ) : (
            <div className="text">Your Asana integration is installed.</div>
          )}
          <Tappable onTap={uninstall}>
            <div className="uninstall">Uninstall</div>
          </Tappable>
        </div>
        {!workspace && (
          <div className="workspace">
            <Dropdown
              className="workspaceDropdown"
              onChange={setSelectedWorkspace}
              options={workspaceOptions}
              placeholder={'Select a workspace...'}
            />
            <Button
              value="Set Workspace"
              disabled={!workspaceID}
              loading={updatingWorkspace}
              onTap={updateWorkspace}
            />
          </div>
        )}
        {workspace && (
          <div className="syncStatus">
            <div className="text">Webhook sync status: {syncStatus}</div>
            <div className="text">Last sync: {syncExecutedAt}</div>
          </div>
        )}
        {workspace && (
          <Rules
            asanaCustomFields={customFields}
            asanaRules={rules}
            onError={setError}
            onRuleCreated={reloadAsanaSettings}
            onRuleDeleted={reloadAsanaSettings}
          />
        )}
      </div>
    );
  };

  const renderInstallButton = () => {
    const { integrationCount, integrationLimit } = activeIntegrations;
    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    const hasLostAccess = !!asanaSettings?.installation?.lostAccess;
    const ctaValue = hasLostAccess ? 'Reinstall Asana' : 'Install Asana';
    const asanaURL = `${AsanaURL}${queryString.stringify({
      ...AsanaParams,
      state: asanaState,
    })}`;

    return (
      <div>
        {hasLostAccess && (
          <div className="text">
            Your Asana integration has lost access to Canny. Please reinstall the integration.
          </div>
        )}
        <a href={asanaURL} className="buttonContainer" rel="noreferrer noopener nofollow">
          <Button value={ctaValue} />
        </a>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="spinnerContainer">
        <Spinner />
      </div>
    );
  };

  const renderError = () => {
    if (!error && asanaSettings) {
      return null;
    }

    const errorMessage = error ?? 'Could not load Asana settings. Please try again later';
    return <div className="error">{errorMessage}</div>;
  };

  return (
    <div className="adminAsanaSettings">
      <Helmet title="Asana Integration | Canny" />
      <div className="settingsHeading">Asana Integration</div>
      <div className="content">
        <div className="text">
          Canny for Asana lets you link Canny posts with Asana tasks and sync fields from Asana to
          Canny. This helps your engineering and product management teams communicate priorities.
        </div>
        <div className="text">
          Check out&nbsp;
          <a
            className="articleLink"
            href="http://help.canny.io/en/articles/7065590-asana-integration"
            rel="noopener"
            target="_blank">
            this help article
          </a>
          &nbsp;to see how this integration works.
        </div>
        {renderContents()}
        {renderError()}
      </div>
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadAsanaSettings: () => dispatch(reloadAsanaSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.asana),
    '/admin/settings'
  )
)(AdminAsanaSettings) as unknown as React.FC<OwnProps>;
