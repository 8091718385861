import React, { useState } from 'react';

import { X } from 'lucide-react';

import AJAX from 'common/AJAX';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import CreateRuleForm from './CreateRuleForm';

import type { AsanaCustomField, AsanaRule } from 'common/api/endpoints/asanaSettings';

type Props = {
  asanaCustomFields: AsanaCustomField[];
  asanaRules: AsanaRule[];
  onError(message: string): void;
  onRuleCreated(): void;
  onRuleDeleted(): void;
};

type RuleOperations = {
  creatingRule: boolean;
  deletingRuleID: string | null;
  savingRule: boolean;
};

const ErrorMessages = {
  'similar rule exists': `There's already a rule assigned to this Asana field for this list. Please, select another one and try again.`,
};

const Rules = ({ asanaCustomFields, asanaRules, onError, onRuleCreated, onRuleDeleted }: Props) => {
  // state
  const [ruleOperations, _setRuleOperations] = useState<RuleOperations>({
    creatingRule: false,
    deletingRuleID: null,
    savingRule: false,
  });

  // state utils
  const setRuleOperations = (data: Partial<RuleOperations>) =>
    _setRuleOperations((state) => ({ ...state, ...data }));

  // helpers
  const deleteRule = async (rule: AsanaRule) => {
    setRuleOperations({ deletingRuleID: rule._id });
    const response = await AJAX.post('/api/asana/deleteRule', {
      ruleID: rule._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      setRuleOperations({ deletingRuleID: null });
      onError(error.message);
      return;
    }

    await onRuleDeleted();
    setRuleOperations({ deletingRuleID: null });
  };

  const openCreateForm = () => setRuleOperations({ creatingRule: true });
  const closeCreateForm = () => setRuleOperations({ creatingRule: false });

  const saveRule = async ({
    allLinkedTasks,
    cannyStatus,
    customFieldID,
    customFieldOptionID,
    shouldNotifyVoters,
  }: {
    allLinkedTasks: boolean;
    cannyStatus: string;
    customFieldID: string;
    customFieldOptionID: string;
    shouldNotifyVoters: boolean;
  }) => {
    setRuleOperations({ savingRule: true });

    const response = await AJAX.post('/api/asana/createRule', {
      allLinkedTasks,
      asanaStatusKeyID: customFieldID,
      asanaStatusValueID: customFieldOptionID,
      cannyStatus,
      shouldNotifyVoters,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: ErrorMessages,
    });

    if (error) {
      onError(error.message);
      setRuleOperations({ savingRule: false });
      return;
    }

    await onRuleCreated();
    setRuleOperations({ creatingRule: false, savingRule: false });
  };

  // renders
  const renderRule = (rule: AsanaRule) => {
    const customField = asanaCustomFields.find((field) => field.id === rule.asanaStatus.keyID);
    const customFieldOption = customField?.enumOptions.find(
      (option) => option.id === rule.asanaStatus.valueID
    );
    const isPlural = rule.allLinkedTasks;

    return (
      <div className="rule" key={rule._id}>
        <div className="description">
          When <p className="underlined">{rule.allLinkedTasks ? 'all' : 'any'}</p> linked Asana{' '}
          {isPlural ? 'tasks are' : 'task is'} changed to{' '}
          <div className="asanaStatusLabel underlined">{customField?.name}</div>:&nbsp;
          <div className="asanaStatusLabel underlined">{customFieldOption?.name}</div>, change all
          linked Canny posts to <PostStatus showOpen={true} status={rule.cannyStatus} />, and{' '}
          {rule.shouldNotifyVoters ? '' : 'do not'} notify voters.
        </div>
        <div className="deleteContainer">
          {ruleOperations.deletingRuleID === rule._id ? (
            <Spinner />
          ) : (
            <Tappable onTap={() => deleteRule(rule)}>
              <div className="iconXContainer">
                <X size={24} />
              </div>
            </Tappable>
          )}
        </div>
      </div>
    );
  };

  const render = () => {
    if (!asanaRules) {
      return null;
    }

    const hasRules = asanaRules.length > 0;

    return (
      <div className="rules">
        <p className="text">
          Set up rules so that when a task's state changes, linked Canny posts are updated as well.
        </p>
        {(hasRules || ruleOperations.creatingRule) && (
          <>
            <UppercaseHeader>Rules</UppercaseHeader>
            <hr />
          </>
        )}
        {hasRules && <div className="ruleList">{asanaRules.map(renderRule)}</div>}
        {ruleOperations.creatingRule ? (
          <CreateRuleForm
            asanaCustomFields={asanaCustomFields}
            loading={ruleOperations.savingRule}
            onClose={closeCreateForm}
            onSubmit={saveRule}
          />
        ) : (
          <Button className="createRuleButton" onTap={openCreateForm} value="Create New Rule" />
        )}
      </div>
    );
  };

  return render();
};

export default Rules;
