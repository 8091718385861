import React, { useContext } from 'react';

import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import UpsellModal from 'common/modals/UpsellModal';
import {
  type Integration,
  SourceType,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import AdminAutopilotIntegrationModal from 'common/subdomain/admin/AdminQueue/GettingStarted/modals/AdminAutopilotIntegrationModal';
import AdminAutopilotReviewIntegrationModal from 'common/subdomain/admin/AdminQueue/GettingStarted/modals/AdminAutopilotReviewIntegrationModal';
import AdminAutopilotSourceFiltersModal from 'common/subdomain/admin/AdminQueue/GettingStarted/modals/AdminAutopilotSourceFiltersModal';
import FreshdeskIntegrationModal from 'common/subdomain/admin/AdminQueue/GettingStarted/modals/FreshdeskIntegrationModal';
import ZendeskIntegrationModal from 'common/subdomain/admin/AdminQueue/GettingStarted/modals/ZendeskIntegrationModal';
import {
  getHelpscoutOAuthUrl,
  getIntercomOAuthURL,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/utils';
import { GrowthPlanID } from 'common/util/isGrowth';
import { StarterPlanID } from 'common/util/isStarter';

import type { Company } from 'common/api/endpoints/companies';

type Props = {
  onClose: () => void;
  integration: Integration | null;
  enableIntegrationSync: (integration: Integration) => void;
  onUninstall?: () => void;
};

const AdminAutopilotGettingStartedModalSwitch = ({
  onClose,
  integration,
  enableIntegrationSync,
  onUninstall,
}: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const { integrationCount, integrationLimit } = useContext(ActiveIntegrationContext);
  const hitIntegrationLimit = integrationLimit && integrationCount >= integrationLimit;

  // render
  if (!integration) {
    return null;
  }

  // Intercom & Zendesk contribute to integration limit, the rest do not
  const Upsell = (
    <UpsellModal
      feature="limits.integrations"
      show
      planID={integrationLimit === 1 ? StarterPlanID : GrowthPlanID}
      onClose={onClose}
      cta="Connect more integrations to Autopilot"
    />
  );

  if (integration.name === SyncIntegrationNames.intercom) {
    if (hitIntegrationLimit && !company.intercom) {
      return Upsell;
    }

    // intercom already installed, load source filters modal
    if (company.intercom) {
      return (
        <AdminAutopilotSourceFiltersModal
          integration={integration}
          onClose={onClose}
          enableIntegrationSync={enableIntegrationSync}
        />
      );
    }

    return (
      <AdminAutopilotIntegrationModal
        integration={integration}
        onClose={onClose}
        enableIntegrationSync={enableIntegrationSync}
        getOAuthURL={() => getIntercomOAuthURL(company.subdomain)}
      />
    );
  }

  if (integration.name === SyncIntegrationNames.freshdesk) {
    return (
      <FreshdeskIntegrationModal
        integration={integration}
        onClose={onClose}
        enableIntegrationSync={enableIntegrationSync}
      />
    );
  }

  if (integration.name === SyncIntegrationNames.zendesk) {
    if (hitIntegrationLimit && !company.zendesk) {
      return Upsell;
    }

    return (
      <ZendeskIntegrationModal
        integration={integration}
        onClose={onClose}
        enableIntegrationSync={enableIntegrationSync}
      />
    );
  }

  if (integration.name === SyncIntegrationNames.helpscout) {
    return (
      <AdminAutopilotIntegrationModal
        integration={integration}
        onClose={onClose}
        enableIntegrationSync={enableIntegrationSync}
        getOAuthURL={() => getHelpscoutOAuthUrl(company.subdomain)}
      />
    );
  }

  if (integration.type === SourceType.review) {
    return (
      <AdminAutopilotReviewIntegrationModal
        onClose={onClose}
        integration={integration}
        onUninstall={onUninstall}
      />
    );
  }

  throw new Error('invalidly defined integration');
};

export default AdminAutopilotGettingStartedModalSwitch;
