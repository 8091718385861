import React, { Component } from 'react';

import AJAX from 'common/AJAX';

import { loadViewer } from '../actions/viewer';
import asyncConnect from '../core/asyncConnect';

import type { Viewer as _Viewer } from 'common/api/endpoints/viewer';

export type Viewer = _Viewer;

// The viewer will always exist, because of the asyncConnect below
export const ViewerContext = React.createContext<Viewer>({} as Viewer);

function createAsyncConnect(options: { includeDocsData?: boolean } = {}) {
  return {
    promise: ({ store: { dispatch } }: PromiseOptions) => {
      return dispatch(loadViewer(options));
    },
  };
}

type Props = {
  viewer: Viewer;
};

class ViewerContainer extends Component<Props> {
  static asyncConnect = createAsyncConnect();

  static createAsyncConnect = createAsyncConnect;

  componentDidMount() {
    const { viewer } = this.props;
    if (!viewer?.csrfToken) {
      return;
    }

    AJAX.injectCookies({
      csrfToken: viewer.csrfToken,
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { viewer } = this.props;
    if (viewer !== prevProps.viewer && viewer?.csrfToken) {
      AJAX.injectCookies({
        csrfToken: viewer.csrfToken,
      });
    }
  }

  render() {
    return (
      <ViewerContext.Provider value={this.props.viewer}>
        {this.props.children}
      </ViewerContext.Provider>
    );
  }
}

export default asyncConnect([createAsyncConnect()], (state) => ({ viewer: state.viewer }))(
  ViewerContainer
);
