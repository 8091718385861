import React from 'react';

import { TerminalSquare } from 'lucide-react';

import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import LazyLoadedImage from 'common/LazyLoadedImage';

import AppStoreLogo from 'img/app-store-logo-small.svg';
import CapterraLogo from 'img/capterra-logo-small.svg';
import FreshdeskLogo from 'img/freshdesk-logo-small.svg';
import G2Logo from 'img/g2-logo-small.svg';
import GongLogo from 'img/gong-logo-small.png';
import GooglePlayLogo from 'img/google-play-logo-small.svg';
import HelpscoutLogo from 'img/helpscout-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import ProductHuntLogo from 'img/product-hunt-logo-small.svg';
import SalesforceLogo from 'img/salesforce-text-logo-small.svg';
import ShopifyLogo from 'img/shopify-logo-small.svg';
import TrustpilotLogo from 'img/trustpilot-logo-small.svg';
import TrustRadiusLogo from 'img/trustradius-logo-small.svg';
import WordpressLogo from 'img/wordpress-logo-small.svg';
import ZapierMediumLogo from 'img/zapier-logo-square-medium.png';
import ZapierSmallLogo from 'img/zapier-logo-square-small.png';
import ZendeskLogo from 'img/zendesk-logo-small.png';
import ZoomLogo from 'img/zoom-logo-small.png';

enum LogoSize {
  small = 'small',
  medium = 'medium',
}

type Logo = string | Record<LogoSize, string>;

const LogoMap: Record<Exclude<SyncIntegrationNames, 'api'>, Logo> = {
  [SyncIntegrationNames.intercom]: IntercomLogo,
  [SyncIntegrationNames.freshdesk]: FreshdeskLogo,
  [SyncIntegrationNames.helpscout]: HelpscoutLogo,
  [SyncIntegrationNames.zendesk]: ZendeskLogo,
  [SyncIntegrationNames.zoom]: ZoomLogo,
  [SyncIntegrationNames.gong]: GongLogo,
  [SyncIntegrationNames.trustpilotReviews]: TrustpilotLogo,
  [SyncIntegrationNames.appStoreReviews]: AppStoreLogo,
  [SyncIntegrationNames.playStoreReviews]: GooglePlayLogo,
  [SyncIntegrationNames.g2Reviews]: G2Logo,
  [SyncIntegrationNames.capterraReviews]: CapterraLogo,
  [SyncIntegrationNames.trustradiusReviews]: TrustRadiusLogo,
  [SyncIntegrationNames.productHuntReviews]: ProductHuntLogo,
  [SyncIntegrationNames.salesforceReviews]: SalesforceLogo,
  [SyncIntegrationNames.wordpressReviews]: WordpressLogo,
  [SyncIntegrationNames.shopifyReviews]: ShopifyLogo,
  [SyncIntegrationNames.zapier]: {
    [LogoSize.small]: ZapierSmallLogo,
    [LogoSize.medium]: ZapierMediumLogo,
  },
};

const AutopilotSourceLogo = ({
  className,
  integrationName,
  size,
}: {
  className: string;
  integrationName: SyncIntegrationNames;
  size: keyof typeof LogoSize;
}) => {
  // this is a special case where we don't we typically show the TerminalSquare icon and so should be handled separately
  if (integrationName === SyncIntegrationNames.api) {
    return <TerminalSquare className={className} />;
  }

  const logoSrc = LogoMap[integrationName];

  if (!logoSrc) {
    return null;
  }

  if (typeof logoSrc === 'string') {
    return <LazyLoadedImage className={className} src={logoSrc} alt={integrationName} />;
  }

  return <LazyLoadedImage className={className} src={logoSrc[size]} alt={integrationName} />;
};

export default AutopilotSourceLogo;
