import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import SentrySDK from 'common/3rd/SentrySDK';
import { reloadOnMerge } from 'common/actions/postMerge';
import { loadQuery } from 'common/actions/postQueries';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import SearchInput from 'common/inputs/SearchInput';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import MergePostSuggestion from 'common/post/MergePostSuggestion';
import Spinner from 'common/Spinner';
import delayer from 'common/util/delayer';
import { getPostQueryKey } from 'common/util/filterPosts';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/post/_MergePostDropdown.scss';

const Delay = 300;
const ErrorMessages = {
  'board deleted': 'The board for one of the posts does not exist anymore',
  'invalid company': "You can't merge posts from another company",
  'not authorized': "You don't have permission to merge posts",
  'post missing': 'One of the posts you are trying to merge does not exist anymore',
  'same posts': 'It is not possible to merge one post with itself',
};
const Pages = 3;
const ScoreFactor = 100000;

class MergePostDropdown extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.object,
    isFloated: PropTypes.bool,
    loadQuery: PropTypes.func,
    onPostMerged: PropTypes.func,
    onPostSelected: PropTypes.func,
    openModal: PropTypes.func,
    permissionKey: PropTypes.string,
    placeholder: PropTypes.string,
    post: PropTypes.object,
    postQueries: PropTypes.object,
    reloadData: PropTypes.func,
    viewer: PropTypes.object,
  };

  static defaultProps = {
    autoFocus: false,
    isFloated: false,
    permissionKey: 'mergePosts',
  };

  state = {
    error: null,
    focused: false,
    merging: false,
    mouseOverSuggestions: false,
    value: null,
  };

  constructor(props, context) {
    super(props, context);

    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this._onChangeDelayer = new delayer(this.onSearchChangeAfterDelay, Delay);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._onChangeDelayer.cancel();
  }

  mapBoardsToQueryKey = (boards) => {
    return boards.map((board) => board.urlName).join('_');
  };

  onMouseOverSuggestions = () => {
    this.setState({
      mouseOverSuggestions: true,
    });
  };

  onMouseLeaveSuggestions = () => {
    this.setState({
      mouseOverSuggestions: false,
    });
  };

  onSearchBlur = () => {
    const { isFloated } = this.props;
    if (!isFloated) {
      this.searchInputRef.current.focus();
      return;
    }

    this.setState({
      focused: false,
    });
  };

  onSearchChange = (searchValue) => {
    const value = searchValue.trim();
    this._onChangeDelayer.cancel();

    if (!value) {
      this.setState({
        selectedSuggestionIndex: 0,
        value,
      });
      return;
    }

    this._onChangeDelayer.callAfterDelay(value);
  };

  onSearchChangeAfterDelay = (value) => {
    const { boards, loadQuery } = this.props;
    loadQuery({
      boards: this.mapBoardsToQueryKey(boards),
      pages: Pages,
      scoreFactor: ScoreFactor,
      textSearch: value,
    }).then(() => {
      this.setState({
        value,
      });
    });
  };

  onSearchFocus = () => {
    const { boards, company, loadQuery, openModal, permissionKey, post, viewer } = this.props;

    if (permissionKey && !hasPermission(permissionKey, company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: [permissionKey],
      });
      this.searchInputRef.current?.blur();
      return;
    }

    this.setState({
      focused: true,
    });

    loadQuery({
      boards: this.mapBoardsToQueryKey(boards),
      pages: Pages,
      scoreFactor: ScoreFactor,
      textSearch: post.title + ' ' + post.details,
    });
  };

  onSuggestedSelected = (intoPost) => {
    const { merging } = this.state;
    const { boards, board } = this.props;

    if (this.props.onPostSelected) {
      this.props.onPostSelected(intoPost);
      return;
    }

    const toBoard = boards.find((board) => board._id === intoPost.boardID);
    const isTurningPublic = board.settings?.private && !toBoard?.settings?.private;
    if (merging) {
      return;
    }

    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: (
        <div className="mergePostDropdownConfirmModal message">
          <div className="part">
            Are you sure you'd like to merge this post into "{intoPost.title}"? All votes and
            comments will be&nbsp;moved.
          </div>
          {isTurningPublic && (
            <div className="part boldPart">
              Warning: You are merging a post from a private board to a public board. All comments
              and votes will be made public.
            </div>
          )}
        </div>
      ),
      onConfirm: this.onMergePost.bind(this, intoPost),
    });
    this.setState({
      error: null,
      mouseOverSuggestions: false,
    });
  };

  onMergePost = async (intoPost) => {
    const { merging } = this.state;
    if (merging) {
      return;
    }

    this.setState({
      merging: true,
    });

    const { boards, board: fromBoard, onPostMerged, post, reloadData } = this.props;
    const response = await AJAX.post('/api/posts/merge', {
      mergePostID: post._id,
      mergePostIntoID: intoPost._id,
    });

    const { error } = parseAPIResponse(response, {
      errors: ErrorMessages,
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({
        error: error.message,
        merging: false,
      });
      return;
    }

    const toBoard = boards.find((board) => board._id === intoPost.boardID);
    await reloadData(post, intoPost, fromBoard, toBoard);
    if (this._isMounted) {
      this.setState({ merging: false });
    }
    onPostMerged(intoPost, toBoard);
  };

  renderSuggestions() {
    const { company } = this.props;
    const { error, focused, mouseOverSuggestions, value } = this.state;

    if (error) {
      return this.renderError();
    }

    if (!focused && !mouseOverSuggestions) {
      return null;
    }

    const { boards, post, postQueries, posts } = this.props;
    const search = value || post.title + ' ' + post.details;
    const queryKey = getPostQueryKey({
      boards: this.mapBoardsToQueryKey(boards),
      scoreFactor: ScoreFactor,
      textSearch: search,
    });

    const queryResult = postQueries[queryKey];
    if (!queryResult || !queryResult.posts || !queryResult.posts.length) {
      return null;
    }

    const suggestions = [];
    queryResult.posts.forEach((postResult) => {
      const { boardID, postURLName } = postResult;
      const postSuggestion = posts[boardID][postURLName];
      if (post._id === postSuggestion._id) {
        return;
      }

      const status = company.statuses.find((status) => status.name === postSuggestion.status);
      if (!status) {
        // If the status cannot be found, it means that elasticsearch posts are out of sync.
        // Skip the post so it doesn't break the experience, but capture the exception for visibility.
        SentrySDK.captureException(
          new Error(`Statuses out of sync in Elasticsearch for company ${company._id}`)
        );
        return;
      }

      suggestions.push(
        <MergePostSuggestion
          key={postSuggestion._id}
          onTap={() => this.onSuggestedSelected(postSuggestion)}
          post={postSuggestion}
        />
      );
    });

    if (!suggestions.length) {
      return null;
    }

    return (
      <div
        className="suggestions"
        onMouseOver={this.onMouseOverSuggestions}
        onMouseLeave={this.onMouseLeaveSuggestions}>
        {suggestions}
      </div>
    );
  }

  renderSearchInput() {
    const { autoFocus, isFloated } = this.props;
    const { focused, merging } = this.state;

    const getPlaceholder = () => {
      if (this.props.placeholder) {
        return this.props.placeholder;
      } else if (merging) {
        return 'Merging...';
      } else if (focused || !isFloated) {
        return 'Search post to merge into\u2026';
      } else {
        return 'Merge into another post';
      }
    };

    const placeholder = getPlaceholder();

    return (
      <div className="searchInputContainer">
        {merging ? <Spinner /> : null}
        <SearchInput
          autoFocus={autoFocus}
          onBlur={this.onSearchBlur}
          onChange={this.onSearchChange}
          onFocus={this.onSearchFocus}
          placeholder={placeholder}
          ref={this.searchInputRef}
          showSearchIcon={false}
        />
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { isFloated } = this.props;

    return (
      <div
        className={classnames('mergePostDropdown', {
          floatingMenu: isFloated,
        })}>
        <div className="searchContainer">
          {this.renderSearchInput()}
          {this.renderSuggestions()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      posts: state.posts,
      postQueries: state.postQueries,
    }),
    (dispatch) => ({
      loadQuery: (queryParams) => {
        return Promise.all([dispatch(loadQuery(queryParams))]);
      },
      reloadData: (mergePost, intoPost, fromBoard, toBoard) => {
        return dispatch(reloadOnMerge(mergePost, intoPost, fromBoard, toBoard));
      },
    })
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(MergePostDropdown);
