import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import {
  SyncIntegrationNames,
  SyncReviewIntegrationNames,
} from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';

export const ActiveIntegrationContext = React.createContext();

const ReviewIntegrationNames = Object.values(SyncReviewIntegrationNames);
const FreeIntegrations = new Set([
  'googleAnalytics',
  ...ReviewIntegrationNames,
  SyncIntegrationNames.gong,
  SyncIntegrationNames.zoom,
  SyncIntegrationNames.helpscout,
  SyncIntegrationNames.freshdesk,
]);

const generateIntegrationObject = (company) => {
  const { activeIntegrations } = company;
  return {
    ...activeIntegrations,
    googleAnalytics: !!company.gaPropertyID,
  };
};

const ActiveIntegrationContainer = ({ children }) => {
  const company = useContext(CompanyContext);
  const activeIntegrations = generateIntegrationObject(company);
  const integrationCount = Object.keys(activeIntegrations)
    .filter((key) => !FreeIntegrations.has(key))
    .map((key) => activeIntegrations[key])
    .reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
  const integrationLimit = company.limits?.integrations;

  return (
    <ActiveIntegrationContext.Provider
      value={{ activeIntegrations, integrationCount, integrationLimit }}>
      {children}
    </ActiveIntegrationContext.Provider>
  );
};

ActiveIntegrationContainer.propTypes = {
  company: PropTypes.object,
};

export default ActiveIntegrationContainer;
