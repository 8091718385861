import React, { useCallback, useContext, useEffect, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadHelpscoutSettings } from 'common/actions/helpscoutSettings';
import AJAX from 'common/AJAX';
import { IntegrationItemNames, SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationAutopilotSettingsSection from 'common/subdomain/admin/AdminQueue/AdminIntegrationAutopilotSettingsSection/AdminIntegrationAutopilotSettingsSection';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';

import type { Company } from 'common/api/endpoints/companies';
import type { HelpscoutSettings } from 'common/api/endpoints/helpscout';

import 'css/components/subdomain/admin/_AdminHelpscoutSettings.scss';

type OwnProps = {
  helpscoutSettings: HelpscoutSettings;
};

type ConnectProps = {
  reloadHelpscoutSettings: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

// For more information, visit: https://developer.helpscout.com/mailbox-api/overview/authentication/
// Note: After access is granted by user, user will be redirected to https://canny.io/helpscout-redirect
const Helpscout = 'helpscout';
const HelpscoutParams = {
  client_id: publicConfig('helpscoutClientID'),
};
const HelpscoutURL = 'https://secure.helpscout.net/authentication/authorizeClientApplication';

const AdminHelpscoutSettings = (props: Props) => {
  // props
  const { helpscoutSettings, reloadHelpscoutSettings } = props;

  // context
  const company = useContext<Company>(CompanyContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);

  // state
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // helpers
  const sendRequest = useCallback(
    async (url: string, params: Record<string, unknown> = {}) => {
      setError(null);
      setLoading(true);

      const response = await AJAX.post(url, params);
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        setLoading(false);
        setError(error.message);
        return;
      }

      await reloadHelpscoutSettings();
      setLoading(false);
    },
    [reloadHelpscoutSettings]
  );

  const install = useCallback(
    async (code: string) => sendRequest('/api/helpscout/install', { code }),
    [sendRequest]
  );

  const uninstall = useCallback(
    async () => sendRequest('/api/helpscout/uninstall', {}),
    [sendRequest]
  );

  // effects
  useEffect(() => {
    const { code, integration } = location.query;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    if (integration === Helpscout) {
      install(code);
    }
  }, [install, location.query, location.pathname, router]);

  // renderers
  const Contents = () => {
    const installation = helpscoutSettings?.installation;
    const hasInstalled = installation && !!installation?.installed && !installation?.lostAccess;

    if (!company?.integrations?.helpscout) {
      return <AdminFeatureUpsell feature="helpscout" />;
    } else if (loading) {
      return (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      );
    } else if (!hasInstalled) {
      return <InstallButton />;
    }

    return (
      <>
        <Alert
          type={AlertTypes.Confirmation}
          className="installationAlert"
          headingText="Help Scout"
          subText="Your Help Scout integration is installed."
          button={
            <ButtonV2 size="medium" onClick={uninstall} color="error">
              Uninstall
            </ButtonV2>
          }
        />
        <AdminIntegrationAutopilotSettingsSection
          integrationLabel="Help Scout"
          integrationName={SyncIntegrationNames.helpscout}
          integrationType={IntegrationItemNames.conversation}
        />
      </>
    );
  };

  const InstallButton = () => {
    const hasLostAccess = !!helpscoutSettings?.installation?.lostAccess;
    const ctaValue = hasLostAccess ? 'Reinstall Help Scout' : 'Install Help Scout';

    const helpscoutParams = {
      ...HelpscoutParams,
      state: JSON.stringify({
        integration: Helpscout,
        subdomain: company.subdomain,
      }),
    };
    const helpscoutURL = `${HelpscoutURL}${queryString.stringify(helpscoutParams)}`;

    return (
      <div>
        {hasLostAccess && (
          <div className="text">
            Your Help Scout integration has lost access to Canny. Please reinstall the integration.
          </div>
        )}
        <a href={helpscoutURL} className="buttonContainer" rel="noreferrer noopener nofollow">
          <Button value={ctaValue} />
        </a>
      </div>
    );
  };

  return (
    <div className="adminHelpscoutSettings">
      <Helmet title="Help Scout Integration | Canny" />
      <div className="settingsHeading">Help Scout Integration</div>
      <div className="content">
        <div className="text">
          Canny will automatically detect and pull in features requests from
          Help&nbsp;Scout&nbsp;conversations.
        </div>
        <div className="text">
          Check out&nbsp;
          <a
            className="articleLink"
            href="http://help.canny.io/en/articles/8242418-beta-help-scout-integration"
            rel="noopener"
            target="_blank">
            this help article
          </a>
          &nbsp;to see how this integration works.
        </div>
        <Contents />
        {error ? <div className="error">{error}</div> : null}
      </div>
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadHelpscoutSettings: () => dispatch(reloadHelpscoutSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.helpScout),
    '/admin/settings'
  )
)(AdminHelpscoutSettings) as unknown as React.FC<OwnProps>;
