import React, { useContext } from 'react';

import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { AutopilotIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import {
  ZapierSources,
  ZoomSource,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import type { Company } from 'common/api/endpoints/companies';

export const getAutopilotSectionIntegrations = (
  company: Company,
  { includeZapier } = { includeZapier: false }
) => {
  return [
    ...AutopilotIntegrations,
    ...(company.featureAllowlist.includes('zoom') ? [ZoomSource] : []),
    ...(includeZapier ? ZapierSources : []),
  ];
};

const AdminIntegrationSettingsAutopilot = () => {
  const company = useContext<Company>(CompanyContext);
  const sections = [
    {
      integrations: getAutopilotSectionIntegrations(company),
    },
    {
      sectionTitle: 'Zapier Templates',
      integrations: ZapierSources,
    },
  ];

  return (
    <AdminIntegrationSettings
      settingsTitle="Autopilot"
      settingsDescription={
        <>
          Enable Autopilot to pull feedback from your sources. Detected feedback will be compared to
          existing posts to identify duplicates.{' '}
          <a
            href="https://help.canny.io/en/articles/8202451-autopilot"
            target="_blank"
            rel="noopener">
            Learn more
          </a>
        </>
      }
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.autopilot),
    '/admin/settings'
  )
)(AdminIntegrationSettingsAutopilot) as unknown as React.FC;
