import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { Check } from 'lucide-react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import Instructions from 'common/subdomain/admin/AdminDashboard/onboarding/Instructions';
import { H4, P } from 'common/ui/Text';
import getOnboardingTasks, { OnboardingLabels } from 'common/util/getOnboardingTasks';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardOnboarding.scss';
import 'css/components/subdomain/admin/_AdminOnboarding.scss';

const AdminDashboardOnboarding = () => {
  // context
  const company = useContext<Company>(CompanyContext);

  // state
  const tasks = getOnboardingTasks(company);
  const nextTask = tasks.find((task) => !task.completed) || tasks[0];
  const [selected, setSelected] = useState<string>(nextTask.name);

  const selectedTaskStatus = tasks.find(({ name }) => name === selected);
  return (
    <div className="adminDashboardOnboarding">
      <div className="taskbar">
        <div className="header">
          <H4 className="title">Getting started</H4>
          <P className="description">
            Here are some actions that will set you up for success with&nbsp;Canny.
          </P>
        </div>
        <div className="tasklist">
          {tasks.map((task, i) => {
            const taskName = task.name as keyof typeof OnboardingLabels;
            const label = OnboardingLabels[taskName];
            return (
              <div
                className={classnames({
                  task: true,
                  completed: task.completed,
                  selected: selected === task.name,
                })}
                key={task.name}
                onClick={() => setSelected(task.name)}>
                <div className="index">{i + 1}</div>
                <div className="check">
                  <Check size={14} />
                </div>
                <div className="label">{label}</div>
                <div className="popper">🎉</div>
              </div>
            );
          })}
        </div>
      </div>
      <Instructions completed={selectedTaskStatus?.completed} taskName={selected} />
    </div>
  );
};

export default AdminDashboardOnboarding;
