import React, { Component } from 'react';

import { X } from 'lucide-react';
import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import Button from 'common/inputs/Button';
import ConfirmModal from 'common/modals/ConfirmModal';
import Alert from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import UppercaseHeader from 'common/UppercaseHeader';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminIntegrationChannelSettings.scss';

class AdminIntegrationChannelSettings extends Component {
  static propTypes = {
    boards: PropTypes.array,
    channel: PropTypes.shape({
      _id: PropTypes.string,
      boardIDs: PropTypes.array,
      notificationTypes: PropTypes.shape({
        newComments: PropTypes.bool,
        newEntries: PropTypes.bool,
        newPosts: PropTypes.bool,
        newVotes: PropTypes.bool,
        statusChanges: PropTypes.bool,
        voteMilestones: PropTypes.bool,
      }),
    }),
    company: PropTypes.object,
    integration: PropTypes.shape({
      deleteURL: PropTypes.string,
      editURL: PropTypes.string,
      getChannelHeading: PropTypes.func,
      reload: PropTypes.func,
    }).isRequired,
    showToast: PropTypes.func,
    openModal: PropTypes.func,
  };

  state = {
    boardIDs: this.props.channel.boardIDs,
    hasChanges: false,
    notificationTypes: this.props.channel.notificationTypes,
    options: this.props.channel.options,
    saved: false,
    saving: false,
  };

  onOptionsChange = (optionName, value) => {
    this.setState((state) => ({
      hasChanges: true,
      options: {
        ...state.options,
        [optionName]: value,
      },
      saved: false,
    }));
  };

  onEventChange = (eventType) => {
    const { notificationTypes } = this.state;
    const isEnabled = !!notificationTypes[eventType];
    this.setState({
      hasChanges: true,
      notificationTypes: {
        ...notificationTypes,
        [eventType]: !isEnabled,
      },
      saved: false,
    });
  };

  onBoardChange = (board) => {
    const { boardIDs } = this.state;
    const newBoardIDs = boardIDs.includes(board._id)
      ? boardIDs.filter((id) => id !== board._id)
      : boardIDs.concat(board._id);

    this.setState({
      boardIDs: newBoardIDs,
      hasChanges: true,
      saved: false,
    });
  };

  onDelete = () => {
    this.props.openModal(ConfirmModal, {
      message: `Are you sure you'd like to remove this channel?`,
      onConfirm: this.onConfirmDelete,
    });
  };

  onConfirmDelete = async () => {
    const { channel, integration, showToast } = this.props;
    const response = await AJAX.post(integration.deleteURL, {
      channelID: channel._id,
    });

    if (response === 'success') {
      integration.reload();
      return;
    }

    showToast('Something went wrong, please try again later.', ToastTypes.error);
  };

  onSave = async () => {
    const { boardIDs, notificationTypes, options } = this.state;
    const { channel, integration, showToast } = this.props;

    this.setState({
      saving: true,
    });

    const response = await AJAX.post(integration.editURL, {
      boardIDs,
      channelID: channel._id,
      notificationTypes,
      options,
    });

    if (response === 'success') {
      await integration.reload();
      this.setState({
        hasChanges: false,
        saved: true,
        saving: false,
      });
      return;
    }

    showToast('Something went wrong, please try again later.', ToastTypes.error);

    this.setState({ saving: false });
  };

  renderBoards() {
    const { boards } = this.props;
    const { boardIDs } = this.state;

    const boardSubscriptions = {};
    boardIDs.forEach((boardID) => {
      boardSubscriptions[boardID] = true;
    });

    return (
      <div className="boardSubscriptions">
        <UppercaseHeader>Boards</UppercaseHeader>
        {boards.map((board) => (
          <CheckboxWithLabel
            key={board._id}
            checked={!!boardSubscriptions[board._id]}
            onChange={() => this.onBoardChange(board)}
            aria-label={`Subscribe to ${board.name}`}>
            {board.name}
          </CheckboxWithLabel>
        ))}
      </div>
    );
  }

  renderEvents() {
    const {
      notificationTypes: {
        newComments,
        newEntries,
        newPosts,
        newVotes,
        statusChanges,
        voteMilestones,
      },
    } = this.state;

    return (
      <div className="eventSubscriptions">
        <UppercaseHeader>Events</UppercaseHeader>
        <CheckboxWithLabel
          checked={newPosts}
          onChange={() => this.onEventChange('newPosts')}
          aria-label="Get notified when a post is created">
          New posts
        </CheckboxWithLabel>
        <CheckboxWithLabel
          checked={newComments}
          onChange={() => this.onEventChange('newComments')}
          aria-label="Get notified when a comment is created">
          New comments
        </CheckboxWithLabel>
        <CheckboxWithLabel
          checked={newVotes}
          onChange={() => this.onEventChange('newVotes')}
          aria-label="Get notified when a new vote is added">
          New votes
        </CheckboxWithLabel>
        <CheckboxWithLabel
          checked={voteMilestones}
          onChange={() => this.onEventChange('voteMilestones')}
          aria-label="Get notified when posts reach 10, 25, 50, etc...&nbsp;votes">
          <Tooltip value="When posts reach 10, 25, 50, etc...&nbsp;votes">
            <div className="eventName">Vote Milestones</div>
          </Tooltip>
        </CheckboxWithLabel>
        <CheckboxWithLabel
          checked={statusChanges}
          onChange={() => this.onEventChange('statusChanges')}
          aria-label="Get notified when a post status changes">
          Status changes
        </CheckboxWithLabel>
        <CheckboxWithLabel
          checked={newEntries}
          onChange={() => this.onEventChange('newEntries')}
          aria-label="Get notified when a new changelog entry is published">
          New changelog entries
        </CheckboxWithLabel>
      </div>
    );
  }

  renderOptions() {
    const { integration } = this.props;
    if (integration.name !== 'slack') {
      return null;
    }

    const {
      options: { allowContributorSpamReports, disableSpamButton },
    } = this.state;
    return (
      <div className="channelOptions">
        <UppercaseHeader>Options</UppercaseHeader>
        <Tooltip value="Usually only Owner and Manager admins can mark as spam">
          <label className="channelOption">
            <input
              className="checkboxInput"
              checked={allowContributorSpamReports}
              onChange={(e) =>
                this.onOptionsChange('allowContributorSpamReports', e.currentTarget.checked)
              }
              type="checkbox"
            />
            <div className="optionName">Allow Contributors to mark posts and comments as spam</div>
          </label>
        </Tooltip>
        <Tooltip value="Control if mark as spam button should be visible on Slack channel notifications">
          <label className="channelOption">
            <input
              className="checkboxInput"
              checked={disableSpamButton}
              onChange={(e) => this.onOptionsChange('disableSpamButton', e.currentTarget.checked)}
              type="checkbox"
            />
            <div className="optionName">Disable spam button</div>
          </label>
        </Tooltip>
      </div>
    );
  }

  renderSaveButton() {
    const { hasChanges, saved, saving } = this.state;
    return (
      <ButtonV2
        className="saveButton"
        disabled={!hasChanges || saving}
        loading={saving}
        onClick={this.onSave}
        size="medium"
        color="primary"
        variant="contained">
        {saved ? 'Saved' : 'Save'}
      </ButtonV2>
    );
  }

  render() {
    const { channel, integration } = this.props;
    return (
      <Card className="adminIntegrationChannelSettings">
        <div className="detailsContainer">
          <div className="channelHeading">
            <P fontWeight="semibold">{integration.getChannelHeading(channel)}</P>
            <div className="channelActions">
              <IconButtonV2
                size="medium"
                icon={X}
                variant="plain"
                onClick={this.onDelete}
                aria-label="Delete connection"
              />
            </div>
          </div>
          {channel.requireReinstallation && (
            <Alert
              type="Danger"
              headingText="Your integration is out-of-date"
              subText="Please update it by deleting the channel notification settings, then re-adding the channel to your Slack"
              button={
                <Button
                  buttonType="ghostButton"
                  className="destructive"
                  value="Delete channel notification settings"
                  onTap={this.onDelete}
                />
              }
            />
          )}
          <P className="explanation">Select the boards and events you want to be notified about.</P>
        </div>
        <div className="subscriptionsContainer">
          {this.renderBoards()}
          {this.renderEvents()}
          {this.renderOptions()}
        </div>
        {this.renderSaveButton()}
      </Card>
    );
  }
}

export default withContexts(
  {
    company: CompanyContext,
    openModal: OpenModalContext,
    showToast: ShowToastContext,
  },
  {
    forwardRef: true,
  }
)(AdminIntegrationChannelSettings);
