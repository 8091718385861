import React, { useContext, useState } from 'react';

import { Plus, Unplug } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import IconButtonV2 from 'common/ui/IconButtonV2';
import SwitchV2 from 'common/ui/SwitchV2';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/settings/_AdminAutopilotIntegrationItem.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { IntegrationItem } from 'common/subdomain/admin/AdminAutopilotSettings/AdminFeedbackDiscoverySettings';

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = IntegrationItem & ConnectProps;

const Icon = ({ logo: Logo }: { logo: IntegrationItem['logo'] }) => {
  if (typeof Logo === 'string') {
    return <LazyLoadedImage src={Logo} className={styles.logo} />;
  }

  return <Logo className={styles.logo} />;
};

const AdminAutopilotIntegrationItem = ({
  logo,
  credits,
  label,
  name,
  reloadCompany,
  type,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const [loading, setLoading] = useState<boolean>(false);

  const integration = company.installedSyncIntegrations.find(
    (integration) => integration.integrationName === name
  );

  const [enabled, setEnabled] = useState<boolean>(integration ? !integration.disabled : false);

  const onToggle = async (checked: boolean) => {
    setEnabled(checked);
    setLoading(true);

    const response = await AJAX.post(`/api/queue/toggleIntegrationSync`, {
      integrationName: name,
      enabled: checked,
    });

    setLoading(false);

    const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
    if (error) {
      setEnabled(!checked);
      showToast(error.message, ToastTypes.error);
      return;
    }

    reloadCompany();
  };

  const isUninstalled =
    !integration || (integration.disabled && integration.disabledReason === 'uninstalled');
  const hasLostAccess =
    !isUninstalled && integration.disabled && integration.disabledReason === 'lostAccess';
  const isActive = !isUninstalled && !hasLostAccess;

  let actions = null;
  if (isActive) {
    actions = <SwitchV2 size="medium" onChange={onToggle} checked={enabled} disabled={loading} />;
  } else if (isUninstalled) {
    actions = (
      <Link to={`/admin/settings/${name}`} className={styles.link}>
        <IconButtonV2
          size="medium"
          icon={Plus}
          variant="outlined"
          aria-label={`Install ${label} source`}
        />
      </Link>
    );
  } else if (hasLostAccess) {
    actions = (
      <>
        <P className={styles.error}>Something went wrong. Re-connect source</P>
        <Link to={`/admin/settings/${name}`} className={styles.link}>
          <IconButtonV2
            size="medium"
            icon={Unplug}
            variant="outlined"
            aria-label={`Re-connect ${label} source`}
          />
        </Link>
      </>
    );
  }

  return (
    <div className={styles.item}>
      <div className={styles.topContainer}>
        <div className={styles.left}>
          <Icon logo={logo} />
          <P variant="bodyMd">{label}</P>
          <Pill pillStyle={DefaultPillStyles.canny} className={styles.creditsPill}>
            {credits > 1 ? `${credits} credits / ${type}` : `${credits} credit / ${type}`}
          </Pill>
        </div>
        <div className={styles.right}>{actions}</div>
      </div>
    </div>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(AdminAutopilotIntegrationItem) as unknown as React.FC<IntegrationItem>;
