import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ChevronDown, ChevronUp } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CountryCodes } from 'common/constants/countries';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import SearchInput from 'common/inputs/SearchInput';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { type Option } from 'common/ui/common/select/SelectCommon';
import IconButtonV2 from 'common/ui/IconButtonV2';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import SwitchV2 from 'common/ui/SwitchV2';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import useDelayer from 'common/util/useDelayer';
import styles from 'css-module/components/subdomain/admin/settings/_AdminAutopilotIntegrationItem.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { IntegrationItem } from 'common/subdomain/admin/AdminAutopilotSettings/AdminFeedbackDiscoverySettings';

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = IntegrationItem & ConnectProps;
type SearchResult = { canSelect: boolean; name: string; link: string | null; score: number };

const RequestErrors = {
  'already installed': 'Integration is already installed.',
  'failed to search': 'Something went wrong, please try again later.',
  'invalid country code': 'Please select a country.',
  'not authorized': "You don't have the permissions to start a trial.",
  'integration limit': 'You have reached your integration limit.',
  'plan does not support': 'Your plan does not support this integration.',
  'failed to install source': 'We could not install the integration, please try again later.',
  'failed to uninstall source': 'We could not uninstall the integration, please try again later.',
  'source not installed': 'Integration is not installed.',
  default: 'Something went wrong, please try again later.',
};

const CountryOptions = Object.entries(CountryCodes).map(([countryName, countryCode]) => ({
  label: countryName,
  value: countryCode,
}));

const getCountryOption = (countryCode: CountryCodes | null) => {
  return CountryOptions.find((option) => option.value === countryCode);
};

const AdminAutopilotReviewIntegrationItem = ({
  logo,
  credits,
  label,
  name,
  needsCountry,
  platform,
  reloadCompany,
  type,
}: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const integrationSync = company.installedSyncIntegrations.find(
    (integration) => integration.integrationName === name
  );
  const integration = company.installedSyncReviewIntegrations.find(
    (integration) => integration.platform === platform
  );
  const defaultSource = integration
    ? { canSelect: true, name: integration.profileName, link: integration.url, score: 1 }
    : null;
  const defaultSearchParams = integration
    ? { search: integration.profileName, countryCode: integration.countryCode as CountryCodes }
    : { search: null, countryCode: null };
  const isEnabled =
    !!integration &&
    (!integrationSync?.disabled || integrationSync?.disabledReason === 'outOfCredits');

  // state
  const [searchParams, setSearchParams] = useState<{
    search: string | null;
    countryCode: CountryCodes | null;
  }>(defaultSearchParams);
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(isEnabled);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchResult[]>([]);
  const [selectedSource, setSelectedSource] = useState<SearchResult | null>(defaultSource);

  const canToggle = loading ? false : enabled ? true : !!selectedSource;
  const canEditFields = loading ? false : !integration && !enabled;

  // ref
  const searchInputRef = useRef<SearchInput>(null);

  // helpers
  const installSource = async () => {
    if (!selectedSource) {
      return { message: 'Fill all the required fields to install the integration' };
    }

    const response = await AJAX.post(`/api/reviewSources/install`, {
      ...(searchParams.countryCode && { countryCode: searchParams.countryCode }),
      platform,
      profileName: selectedSource.name,
      url: selectedSource.link,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: RequestErrors,
    });

    return error;
  };

  const uninstallSource = async () => {
    const response = await AJAX.post(`/api/reviewSources/uninstall`, {
      platform,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: RequestErrors,
    });

    if (error) {
      return error;
    }

    return null;
  };

  const resetSearch = () => {
    setSearchParams((params) => ({ ...params, search: null }));
    setSelectedSource(null);
    searchInputRef.current?.setValue('');
    return null;
  };

  const toggleIntegrationSync = async (enabled: boolean) => {
    const response = await AJAX.post(`/api/queue/toggleIntegrationSync`, {
      integrationName: name,
      enabled,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: RequestErrors,
    });
    return error;
  };

  const searchReviewSource = useCallback(async () => {
    const { search, countryCode } = searchParams;

    if (!search || (needsCountry && !countryCode)) {
      return;
    }

    if (selectedSource && selectedSource.name === search) {
      return;
    }

    const response = await AJAX.post(`/api/reviewSources/search`, {
      ...(countryCode && { countryCode }),
      platform,
      search: search.toLowerCase(),
    });

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (response: { results: SearchResult[] }) => !!response.results,
      errors: RequestErrors,
    });

    if (error || !parsedResponse) {
      const message = error ? error.message : RequestErrors.default;
      showToast(message, ToastTypes.error);
      return;
    }

    const results = parsedResponse.results.length
      ? parsedResponse.results.map((result) => ({ ...result, canSelect: true }))
      : [{ canSelect: false, name: 'No results found...', link: null, score: 1 }];

    setSearchSuggestions(results);
  }, [selectedSource, needsCountry, searchParams, platform, showToast]);

  const onSearchInputChangedAfterDelay = async (search: string) => {
    setSearchParams((prev) => ({ ...prev, search }));
    setSearchSuggestions([]);
  };

  const searchDelayer = useDelayer(onSearchInputChangedAfterDelay, 500);

  const onSelectCountry = (option: Option | undefined) => {
    if (!option) {
      return;
    }

    setSearchParams((prev) => ({ ...prev, countryCode: option.value as CountryCodes }));
  };

  const onSelectSource = (source: SearchResult) => {
    setSelectedSource(source);
    setSearchParams((prev) => ({ ...prev, search: source.name }));
    setSearchSuggestions([]);
    searchInputRef.current?.setValue(source.name);
  };

  const onToggle = async (checked: boolean) => {
    setEnabled(checked);
    setLoading(true);

    // If integration is not installed yet we first need to install it and then enable sync
    // If integration is already installed we first need to disable sync and then uninstall it
    const actions = checked
      ? [
          installSource,
          async () => toggleIntegrationSync(checked),
          async () => {
            await reloadCompany();
            return null;
          },
        ]
      : [
          () => toggleIntegrationSync(checked),
          uninstallSource,
          async () => {
            await reloadCompany();
            return null;
          },
          resetSearch,
        ];

    for (const action of actions) {
      const error = await action();
      if (error) {
        setLoading(false);
        setEnabled(!checked);
        showToast(error.message, ToastTypes.error);
        return;
      }
    }

    setLoading(false);
  };

  const onToggleSettings = () => {
    setShowSettings((showSettings) => !showSettings);
  };

  // effects
  useEffect(() => {
    searchReviewSource();
  }, [searchParams, searchReviewSource]);

  // render
  const renderSuggestions = () => {
    if (!searchSuggestions.length) {
      return null;
    }

    const items = searchSuggestions.map((suggestion) => (
      <button
        className={styles.searchSuggestion}
        key={suggestion.name}
        disabled={!suggestion.canSelect}
        onClick={() => onSelectSource(suggestion)}>
        <P variant="bodyMd">{suggestion.name}</P>
        {suggestion.link && (
          <>
            :&nbsp;
            <a
              className={styles.sourceLink}
              href={suggestion.link}
              rel="noopener"
              target="_blank"
              onClick={(e) => e.stopPropagation()}>
              See Source
            </a>
          </>
        )}
      </button>
    ));

    return <div className={styles.searchSuggestions}>{items}</div>;
  };

  return (
    <div className={styles.item}>
      <div className={styles.topContainer}>
        <div className={styles.left}>
          <LazyLoadedImage src={logo as string} className={styles.logo} />
          <P variant="bodyMd">{label}</P>
          <Pill pillStyle={DefaultPillStyles.canny} className={styles.creditsPill}>
            {credits > 1 ? `${credits} credits / ${type}` : `${credits} credit / ${type}`}
          </Pill>
        </div>
        <div className={styles.right}>
          <SwitchV2 size="medium" onChange={onToggle} checked={enabled} disabled={!canToggle} />
          <IconButtonV2
            size="medium"
            onClick={onToggleSettings}
            icon={showSettings ? ChevronUp : ChevronDown}
            variant="plain"
            aria-label="See settings"
          />
        </div>
      </div>
      {showSettings && (
        <div className={styles.bottomContainer}>
          <div className={styles.formSection}>
            <P variant="bodyMd" className={styles.label}>
              Search<span className={styles.requiredField}>&#42;</span>
            </P>
            <div className={styles.inputContainer}>
              <SearchInput
                className={styles.searchInputContainer}
                disabled={!canEditFields}
                placeholder="Search your product..."
                onChange={searchDelayer}
                defaultValue={searchParams.search}
                showClearIcon={false}
                showSearchIcon={false}
                autoFocus={false}
                ref={searchInputRef}
                styling="v2"
              />
              {renderSuggestions()}
            </div>
          </div>
          {needsCountry && (
            <div className={styles.formSection}>
              <P variant="bodyMd" className={styles.label}>
                Country<span className={styles.requiredField}>&#42;</span>
              </P>
              <div className={styles.inputContainer}>
                <SingleSelectWithSearch
                  headClassName={styles.countryDropdownHead}
                  value={getCountryOption(searchParams.countryCode)}
                  disabled={!canEditFields}
                  onChange={onSelectCountry}
                  options={CountryOptions}
                  placeholder={searchParams.countryCode ? undefined : 'Select country'}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(AdminAutopilotReviewIntegrationItem) as unknown as React.FC<IntegrationItem>;
