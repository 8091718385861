import loadScript from 'common/util/loadScript';

function shouldUseSentry() {
  if (__DEV__) {
    return false;
  } else if (typeof window === 'undefined') {
    return false;
  } else {
    return true;
  }
}

const IgnoreErrors = [
  '$ is not defined',
  'androidInterface is not defined',
  "Can't find variable: pktAnnotationHighlighter",
  'Cannot redefine property: BetterJsPop',
  'Cannot redefine property: blockAdBlock',
  'Cannot redefine property: BlockAdBlock',
  'Cannot redefine property: googletag',
  'CoinCube is not defined',
  'GetScreenshotBoundingBox is not defined',
  'NS_ERROR_FAILURE',
  "ReferenceError: Can't find variable: cordova",
  'ReferenceError: grecaptcha is not defined',
  'vid_mate_check is not defined',
];

const errorQueue = [];

const SentrySDK = {
  captureException: function (error) {
    if (!shouldUseSentry()) {
      console.error(error);
      return;
    }

    if (!window.Sentry) {
      if (errorQueue.length < 100) {
        errorQueue.push(error);
      }
      return;
    }

    window.Sentry.captureException(error);
  },

  init: function (dsn) {
    if (!dsn) {
      console.error('[Sentry]: Missing DSN for init');
      return;
    }

    if (!shouldUseSentry()) {
      return;
    }

    loadScript(`https://js.sentry-cdn.com/${dsn}.min.js`, 'sentry-script', {
      attributes: {
        crossorigin: 'anonymous',
      },
      callback: () => {
        // sentry script is blocked
        if (!window.Sentry) {
          return;
        }

        window.Sentry.init({
          ignoreErrors: IgnoreErrors,
          release: __VERSION__,
          environment: __DEV_ENV__ ? 'dev' : __STAGING__ ? 'staging' : 'production',
          ...(!__BROWSER_EXTENSION__ && {
            allowUrls: [/canny\.io/i],
          }),
        });
        errorQueue.forEach((error) => {
          window.Sentry.captureException(error);
        });
        errorQueue.length = 0;
      },
    });
  },
};

export default SentrySDK;
