import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { ChevronDown, ChevronUp } from 'lucide-react';

import { P } from 'common/ui/Text';

import 'css/components/common/_Accordion.scss';

export type Props = {
  children: React.ReactNode;
  className?: string;
  showDivider?: boolean;
  title: React.ReactNode;
};

const Accordion = ({ children, className, showDivider, title }: Props) => {
  const [open, setOpen] = useState<boolean>(true);

  // generate a random key for each accordion
  const keyRef = useRef(Math.random().toString(36).substring(7));
  const key = keyRef.current;

  const stringTitle = typeof title === 'string';

  return (
    <div className={classnames('accordion', className, { closed: !open })}>
      <div className="accordionHeader">
        {/* If the title is a string, wrap it with a button. If not, no button wrapper */}
        {!stringTitle ? title : null}
        <button
          className={classnames('unstyledButton', {
            rightButton: !stringTitle,
            fullWidthButton: stringTitle,
          })}
          aria-label="toggle for accordion"
          aria-expanded={open}
          aria-controls={`accordion_body_${key}`}
          onClick={() => setOpen((v) => !v)}>
          {stringTitle ? (
            <P variant="bodyMd" fontWeight="medium">
              {title}
            </P>
          ) : null}
          {open ? <ChevronUp /> : <ChevronDown />}
        </button>
      </div>
      {showDivider && <div className="horizontalDivider" />}
      <div
        className="accordionBody"
        role="region"
        aria-label={`accordion in ${open ? 'expanded' : 'collapsed'} state`}
        id={`accordion_body_${key}`}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
