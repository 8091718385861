import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { invalidateSuggestions, loadSuggestions } from 'common/actions/userSuggestions';
import connect from 'common/core/connect';
import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import mod from 'common/mod';
import Tappable from 'common/Tappable';
import UserLockup from 'common/user/UserLockup';
import delayer from 'common/util/delayer';

import 'css/components/company/_CompanyUserSearch.scss';

const ChangeDelay = 300;

class CompanyUserSearch extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onNewUserSelected: PropTypes.func.isRequired,
    onUserSelected: PropTypes.func.isRequired,
    userSuggestions: PropTypes.object,
    suggestedUser: PropTypes.string,
    userPlaceholder: PropTypes.string,
  };

  static defaultProps = {
    userPlaceholder: 'Search for a user...',
  };

  state = {
    creatingNewUser: false,
    newUserEmail: null,
    userPrefix: '',
    value: '',
  };

  constructor(props, context) {
    super(props, context);

    this._onChangeDelayer = new delayer(this.onChangeAfterDelay, ChangeDelay);
    this.nameRef = React.createRef();
    this.emailRef = React.createRef();
  }

  componentDidMount() {
    const { suggestedUser } = this.props;
    const { value } = this.state;

    if (suggestedUser && !value) {
      this.setState({ value: suggestedUser }, this.onChangeAfterDelay);
      return;
    }
  }

  componentWillUnmount() {
    this._onChangeDelayer.cancel();
    this.props.invalidateSuggestions();
  }

  getFormValues = () => {
    const name = this.nameRef.current?.getValue().trim();
    const email = this.emailRef.current?.getValue().trim();

    return { email, name };
  };

  resetFormValues = () => {
    this.nameRef?.current?.setValue('');
    this.emailRef.current?.setValue('');

    this.setState({
      creatingNewUser: false,
      selectedSuggestionIndex: 0,
      selectedUser: null,
      userPrefix: null,
    });
  };

  refocusForm = () => {
    this.nameRef.current?.focus();
  };

  shouldRenderSuggestions = () => {
    const { creatingNewUser, selectedUser, userPrefix, value } = this.state;
    const { disabled, userSuggestions } = this.props;

    if (!value || !userPrefix || disabled || creatingNewUser || selectedUser) {
      return false;
    }

    const suggestions = userSuggestions[userPrefix];
    if (!Array.isArray(suggestions?.items)) {
      return false;
    }

    return true;
  };

  onChange = (e) => {
    const { value } = e.nativeEvent.target;
    if (!value) {
      this.setState({ selectedSuggestionIndex: 0, userPrefix: null });
      return;
    }

    this.setState({ value });
    this._onChangeDelayer.callAfterDelay(e);
  };

  onChangeAfterDelay = () => {
    const { value } = this.state;
    if (!value || value.length === 0) {
      return;
    }

    this.props.loadSuggestions(value).then(() => {
      this.setState({
        selectedSuggestionIndex: 0,
        userPrefix: value,
      });
    });
  };

  onKeyDown = (e) => {
    const { selectedSuggestionIndex, userPrefix } = this.state;
    const { userSuggestions } = this.props;
    const suggestions = userSuggestions[userPrefix];
    if (!suggestions || !suggestions.items || !suggestions.items.length) {
      return;
    }

    const { DownArrow, UpArrow, Enter, Tab } = KeyCodes;
    if (
      e.keyCode !== DownArrow &&
      e.keyCode !== UpArrow &&
      e.keyCode !== Enter &&
      e.keyCode !== Tab
    ) {
      return;
    }

    e.preventDefault();

    if (e.keyCode === DownArrow) {
      this.setState({
        selectedSuggestionIndex: mod(selectedSuggestionIndex + 1, suggestions.items.length + 1),
      });
    } else if (e.keyCode === UpArrow) {
      this.setState({
        selectedSuggestionIndex: mod(selectedSuggestionIndex - 1, suggestions.items.length + 1),
      });
    } else if (e.keyCode === Enter || e.keyCode === Tab) {
      if (selectedSuggestionIndex < suggestions.items.length) {
        this.onUserSelected(suggestions.items[selectedSuggestionIndex]);
      } else {
        this.onNewUserSelected();
      }
    }
  };

  onEmailInputChange = (e) => {
    this.setState({ newUserEmail: e.target.value });
  };

  onNewUserSelected = () => {
    this.setState({ creatingNewUser: true });
    this.props.onNewUserSelected();
  };

  onUserSelected = (selectedUser) => {
    this.nameRef?.current?.setValue(selectedUser.name);
    this.setState({ selectedUser });
    this.props.onUserSelected(selectedUser);
  };

  renderEmailInput() {
    if (!this.state.creatingNewUser) {
      return null;
    }
    return (
      <TextInput
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        onChange={this.onEmailInputChange}
        placeholder="User email (optional)"
        ref={this.emailRef}
      />
    );
  }

  renderOverlay() {
    if (!this.shouldRenderSuggestions()) {
      return;
    }

    return <div className="overlay" />;
  }

  renderSelectedUser() {
    const { selectedUser } = this.state;
    return <UserLockup showCompanyNames={true} showProfile={false} user={selectedUser} />;
  }

  renderUserSuggestions() {
    const { selectedSuggestionIndex, userPrefix, value } = this.state;
    const { userSuggestions, suggestedUser } = this.props;
    const suggestions = userSuggestions[userPrefix];

    if (!this.shouldRenderSuggestions()) {
      return null;
    }

    if (suggestions.items.length === 1 && suggestedUser === value) {
      const [suggestion] = suggestions.items;
      this.onUserSelected(suggestion);
      return null;
    }

    const items = [];
    suggestions.items.forEach((suggestion, i) => {
      items.push(
        <Tappable key={suggestion._id} onTap={this.onUserSelected.bind(this, suggestion)}>
          <div className={classnames('suggestion', { selected: i === selectedSuggestionIndex })}>
            <UserLockup showCompanyNames={true} showProfile={false} user={suggestion} />
          </div>
        </Tappable>
      );
    });

    const newSelected = suggestions.items.length === selectedSuggestionIndex;
    items.push(
      <Tappable key="create" onTap={this.onNewUserSelected}>
        <div className={classnames('suggestion', 'createNew', { selected: newSelected })}>
          <div className="icon icon-plus" />
          {'Create new user'}
        </div>
      </Tappable>
    );

    return (
      <div className="focusFields">
        <div className="userSuggestions">{items}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="companyUserSearch">
        {this.renderOverlay()}
        <TextInput
          autoFocus={this.props.autoFocus}
          defaultValue={this.props.suggestedUser}
          disabled={this.props.disabled}
          onBlur={this.props.onBlur}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          placeholder={this.props.userPlaceholder}
          ref={this.nameRef}
        />
        {this.renderEmailInput()}
        {this.renderUserSuggestions()}
      </div>
    );
  }
}

export default connect(
  (state) => ({ userSuggestions: state.userSuggestions }),
  (dispatch) => ({
    loadSuggestions: (userPrefix) => {
      return Promise.all([dispatch(loadSuggestions(userPrefix))]);
    },
    invalidateSuggestions: () => {
      return dispatch(invalidateSuggestions());
    },
  }),
  { withRef: true }
)(CompanyUserSearch);
