import React, { useContext } from 'react';

import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import withAccessControl from 'common/routing/withAccessControl';
import { getAutopilotSectionIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings//subroutes/AdminIntegrationSettingsAutopilot';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { IntegrationCards } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { getCategorySections } from 'common/subdomain/admin/AdminIntegrationSettings/subroutes/AdminIntegrationSettingsCategories';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import type { Company } from 'common/api/endpoints/companies';

const AdminIntegrationSettingsAll = () => {
  const company = useContext<Company>(CompanyContext);

  const sections = [
    {
      sectionTitle: 'New Integrations',
      sectionDescription: 'Explore recent additions to the Canny catalog by popular demand',
      integrations: [IntegrationCards.freshdesk],
    },
    {
      sectionTitle: (
        <>
          Autopilot Sources{' '}
          <a
            href="https://help.canny.io/en/articles/8202451-autopilot"
            target="_blank"
            rel="noopener">
            Learn more
          </a>
        </>
      ),
      integrations: getAutopilotSectionIntegrations(company, { includeZapier: true }),
    },
    ...getCategorySections(),
  ];

  return (
    <AdminIntegrationSettings
      settingsTitle="All"
      settingsDescription="Browse integrations across all categories, featuring the most recent additions and community favorites"
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.all),
    '/admin/settings'
  )
)(AdminIntegrationSettingsAll) as unknown as React.FC;
