import React, { useContext, useRef, useState } from 'react';

import classnames from 'classnames';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { DefaultTintColor, TintColorContext } from 'common/containers/TintColorContainer';
import { type Viewer, ViewerContext } from 'common/containers/ViewerContainer';
import generateRandomID from 'common/generateRandomID';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import UppercaseHeader from 'common/UppercaseHeader';
import capitalizeWords from 'common/util/capitalizeWords';
import mapify from 'common/util/mapify';

import type { CompanyPostStatus } from 'common/api/endpoints/companies';

type OptionType = 'filter' | 'sort' | 'status';

type Option = {
  name: string;
  render: string;
  isVisible?: (viewer: Viewer) => boolean;
  type: OptionType;
};

const FilterOptions: Option[] = [
  {
    name: 'my',
    render: 'My\u00a0Own',
    isVisible: (viewer) => viewer && !viewer.loggedOut,
    type: 'filter',
  },
  {
    name: 'myCompanies',
    render: 'My\u00a0Teams',
    isVisible: (viewer) => viewer && !viewer.loggedOut && viewer.hasThirdPartyMemberships,
    type: 'filter',
  },
];
const FilterOptionsMap = mapify(FilterOptions, 'name');

const SortOptions: Option[] = [
  {
    name: 'trending',
    render: 'Trending',
    type: 'sort',
  },
  {
    name: 'top',
    render: 'Top',
    type: 'sort',
  },
  {
    name: 'new',
    render: 'New',
    type: 'sort',
  },
];
const SortOptionsMap = mapify(SortOptions, 'name');

const getStatusOptions = (statuses: CompanyPostStatus[]): Option[] => {
  return statuses
    .filter((status) => ![PostStatusTypes.Initial, PostStatusTypes.Closed].includes(status.type))
    .map((status) => {
      const statusName = translateString(status, 'name');
      return {
        name: status.urlName,
        render: capitalizeWords(statusName).replace(' ', '\u00a0'),
        type: 'status',
      };
    });
};

const getSelectedOption = (statusOptions: Option[]): Option => {
  const statusOptionsMap = mapify(statusOptions, 'name');
  const params = new URLSearchParams(typeof window !== 'undefined' ? window?.location.search : '');
  const filter = params.get('filter'),
    sort = params.get('sort'),
    status = params.get('status');

  if (sort && SortOptionsMap[sort]) {
    return SortOptionsMap[sort];
  } else if (status && statusOptionsMap[status]) {
    return statusOptionsMap[status];
  } else if (filter && FilterOptionsMap[filter]) {
    return FilterOptionsMap[filter];
  }

  return SortOptions[0];
};

type DropdownItemProps = {
  active: boolean;
  componentID: string;
  link: string;
  option: Option;
  onSelect: () => void;
};

const optionID = (componentID: string, option: Option) =>
  `filterSortDropdown-${componentID}-${option.type}-${option.name}`;

const DropdownItem = ({ active, componentID, link, option, onSelect }: DropdownItemProps) => {
  const tintColor = useContext(TintColorContext);
  return (
    <Link
      to={link}
      onTap={onSelect}
      id={optionID(componentID, option)}
      role="option"
      aria-selected={active}>
      <div className={classnames('option', { selected: active })}>
        <div
          className="dot"
          style={{
            backgroundColor: tintColor ?? DefaultTintColor,
          }}
        />
        {option.render}
      </div>
    </Link>
  );
};

const FilterSortDropdown = () => {
  const [open, setOpen] = useState(false);
  const { pathname, query } = useContext(LocationContext);
  const viewer = useContext(ViewerContext);
  const company = useContext(CompanyContext);
  const componentID = useRef(generateRandomID());

  const dropdownRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  useBackgroundClick(() => {
    setOpen(false);
  }, [dropdownRef, contentRef]);

  const optionPath = (option: Option) => {
    // Strip unwanted arguments and add the option
    const { filter, search, sort, status, ...newQuery } = query;
    newQuery[option.type] = option.name;
    const queryParams = new URLSearchParams(newQuery);
    return `${pathname}?${queryParams.toString()}`;
  };

  const statusOptions = getStatusOptions(company.statuses);
  const filterOptions = FilterOptions.filter((option) => option.isVisible?.(viewer) ?? true);

  const selectedOption = getSelectedOption(statusOptions);

  return (
    <div className="dropdown">
      <button
        onClick={() => setOpen(!open)}
        className="selector"
        ref={dropdownRef}
        role="combobox"
        aria-activedescendant={optionID(componentID.current, selectedOption)}
        aria-controls={componentID.current}
        aria-expanded={open}
        aria-haspopup="listbox">
        <div className="selectedName">{selectedOption.render}</div>
        <div className="icon-chevron-down" aria-hidden="true" />
      </button>
      <div
        className={classnames('dropdownContent', { hidden: !open })}
        ref={contentRef}
        id={componentID.current}
        role="listbox">
        <div className="sorts" role="none">
          <UppercaseHeader>Sort</UppercaseHeader>
          {SortOptions.map((option) => (
            <DropdownItem
              key={option.name}
              active={option === selectedOption}
              componentID={componentID.current}
              link={optionPath(option)}
              option={option}
              onSelect={() => setOpen(false)}
            />
          ))}
        </div>
        <div className="filters" role="none">
          <UppercaseHeader>Filter</UppercaseHeader>
          {statusOptions.map((option) => (
            <DropdownItem
              key={option.name}
              active={option === selectedOption}
              componentID={componentID.current}
              link={optionPath(option)}
              option={option}
              onSelect={() => setOpen(false)}
            />
          ))}
          {filterOptions.map((option) => (
            <DropdownItem
              key={option.name}
              active={option === selectedOption}
              componentID={componentID.current}
              link={optionPath(option)}
              option={option}
              onSelect={() => setOpen(false)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterSortDropdown;
