import { RelativeDateOptions } from 'common/util/dateRanges';
import { dayjs } from 'common/util/dayjsUtils';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import mapify from 'common/util/mapify';

const SortMap = {
  mrr: 'mrr',
  new: 'newest',
  old: 'oldest',
  opportunity: 'opportunity',
  statusChanged: 'statusChanged',
  top: 'score',
  trending: 'trendingScore',
};

// Helper Functions
function formatDateRangeString(dateRangeString) {
  let dateRange = dateRangeString.split('_');
  if (dateRange.length === 1 && RelativeDateOptions[dateRange[0]]) {
    // It's a relative date, so let's get the date range based on today.
    dateRange = RelativeDateOptions[dateRange[0]].toRange();
  }
  if (dateRange.length === 2) {
    return [
      dayjs(dateRange[0]).utcOffset(0).startOf('day').utc().format(),
      dayjs(dateRange[1]).utcOffset(0).endOf('day').utc().format(),
    ];
  }
}

export function formatCustomQueries(queries) {
  try {
    const decodedQueries = JSON.parse(decodeURIComponent(queries));
    return Object.keys(decodedQueries).map((queryKey) => ({
      fieldID: queryKey,
      operator: decodedQueries[queryKey].operator,
      value: decodedQueries[queryKey].value,
    }));
  } catch {
    return [];
  }
}

export function getQueryFilterParams(board, company, location, params, roadmap) {
  const { segments } = company;
  const { query, state } = location;
  const similarPostSuggestions =
    state && state.createForm && (state.createForm.title || state.createForm.details);

  var textSearch = null;
  if (similarPostSuggestions) {
    textSearch = ((state.createForm.title || '') + ' ' + (state.createForm.details || '')).trim();
  } else if (typeof query.search === 'string') {
    textSearch = decodeQueryTextParam(query.search)?.trim();
  }

  const segment = segments && segments.find(({ urlName }) => urlName === query.segment);
  const isAdminFeedbackView = location.pathname.startsWith('/admin/feedback');

  const searchAllBoards = !params.isWidget && textSearch && company.enableGlobalPostSearch;
  const pages = params.pages;

  // example param description : { 601c400ebbb357463aad3f70: { operator: 'contains', value: 'word' }}
  const descriptionColumnFilters = query.description ? formatCustomQueries(query.description) : [];
  const factorFilters = query.factor ? formatCustomQueries(query.factor) : [];

  // The difference between `currentBoard` and `board` is that `board` is used to indicate a specific board should be queried.
  // `currentBoard` indicates to the API that the user is viewing a board, but not that the board should be necessarily queried.
  // This is used in the case of searching all boards, indicating that the current board, even if it's unlisted, can be searched.
  return {
    ...(!isAdminFeedbackView && board && { currentBoard: board }),
    ...(!isAdminFeedbackView && !searchAllBoards && board && { board }),
    ...(textSearch && { textSearch }),
    ...(pages && { pages }),
    ...(!similarPostSuggestions && {
      ...(!textSearch && typeof query.category === 'string' && { category: query.category }),
      ...(typeof query.boards === 'string' && { boards: query.boards }),
      ...(typeof query.categories === 'string' && { categories: query.categories }),
      ...(typeof query['post-created'] === 'string' && { ['post-created']: query['post-created'] }),
      ...(typeof query['vote-created'] === 'string' && { ['vote-created']: query['vote-created'] }),
      ...(typeof query['eta'] === 'string' && { ['eta']: query['eta'] }),
      ...(query.filter && { filter: query.filter }),
      ...(query.owner && { owner: query.owner }),
      ...(segment && { segment: segment.urlName }),
      ...(query.sort && { sort: query.sort }),
      ...(typeof query.status === 'string' && { status: query.status }),
      ...(typeof query.tags === 'string' && { tags: query.tags }),
      ...(typeof query.companies === 'string' && { companies: query.companies }),
      ...(typeof query.accountOwner === 'string' && { accountOwner: query.accountOwner }),
      ...(query.unassigned && { unassigned: true }),
      ...(query.uncategorized && { uncategorized: true }),
      ...(query.untagged && { untagged: true }),
      ...(descriptionColumnFilters.length && { descriptionColumnFilters }),
      ...(factorFilters.length && { factorFilters }),
      ...(roadmap && { roadmap }),
    }),
  };
}

export function mapQueryFilterParamsToRequestData(
  queryParams,
  company,
  { supplementalOwners } = {}
) {
  const requestData = {
    pages: queryParams.pages ? queryParams.pages : 1,
    sort: SortMap[queryParams.sort] || (queryParams.textSearch ? 'relevance' : 'trendingScore'),
  };
  if (queryParams.currentBoard) {
    requestData.currentBoard = queryParams.currentBoard.urlName;
  }
  if (typeof queryParams.boards !== 'undefined') {
    requestData.boardURLNames = queryParams.boards.split('_').filter((boardURLName) => {
      return !!boardURLName;
    });
  } else if (queryParams.board) {
    requestData.boardURLNames = [queryParams.board.urlName];
  }
  if (typeof queryParams.category !== 'undefined') {
    requestData.categoryURLNames = [queryParams.category];
  }
  if (typeof queryParams.categories !== 'undefined') {
    requestData.categoryURLNames = queryParams.categories.split('_');
  }
  if (typeof queryParams.companies !== 'undefined') {
    requestData.companyURLNames = queryParams.companies.split('_').filter(Boolean);
  }
  if (typeof queryParams.accountOwner !== 'undefined') {
    requestData.accountOwnerName = queryParams.accountOwner;
  }
  if (queryParams['post-created']) {
    const dateRangeString = queryParams['post-created'];
    requestData.postCreatedDateRange = formatDateRangeString(dateRangeString);
  }
  if (queryParams['vote-created']) {
    const dateRangeString = queryParams['vote-created'];
    requestData.voteCreatedDateRange = formatDateRangeString(dateRangeString);
  }
  if (queryParams['eta']) {
    const dateRangeString = queryParams['eta'];
    requestData.etaDateRange = formatDateRangeString(dateRangeString);
  }
  if (queryParams.filter === 'my') {
    requestData.my = true;
  }
  if (queryParams.filter === 'myCompanies') {
    requestData.myCompanies = true;
  }
  if (queryParams.roadmap) {
    requestData.roadmapID = queryParams.roadmap._id;
  }
  if (queryParams.owner) {
    const ownerIDs = [];
    const owners = queryParams.owner.split('_');
    owners.forEach((owner) => {
      const member = company.members.find((member) => {
        return member.urlName === owner;
      });
      if (member) {
        ownerIDs.push(member._id);
      } else if (supplementalOwners.length > 0) {
        // check supplementalOwners
        const supplementalMember = supplementalOwners.find((member) => {
          return member.urlName === owner;
        });
        if (supplementalMember) {
          ownerIDs.push(supplementalMember._id);
        }
      }
    });

    if (ownerIDs.length) {
      requestData.ownerIDs = ownerIDs;
    }
  }
  if (queryParams.scoreFactor) {
    requestData.scoreFactor = queryParams.scoreFactor;
  }
  if (queryParams.segment) {
    requestData.segmentURLName = queryParams.segment;
  }
  if (typeof queryParams.status !== 'undefined') {
    const statusMap = mapify(company.statuses, 'urlName');
    requestData.status = {
      $in: queryParams.status
        .split('_')
        .map((statusURLName) => {
          return statusMap[statusURLName]?.name;
        })
        .filter(Boolean),
    };
  }
  if (typeof queryParams.tags !== 'undefined') {
    requestData.tagURLNames = queryParams.tags.split('_');
  }
  if (queryParams.textSearch) {
    requestData.textSearch = queryParams.textSearch;
  }
  if (queryParams.unassigned) {
    requestData.unassigned = true;
  }
  if (queryParams.uncategorized) {
    requestData.uncategorized = true;
  }
  if (queryParams.untagged) {
    requestData.untagged = true;
  }
  if (queryParams.descriptionColumnFilters) {
    requestData.descriptionColumnFilters = queryParams.descriptionColumnFilters;
  }
  if (queryParams.factorFilters) {
    requestData.factorFilters = queryParams.factorFilters;
  }
  return requestData;
}

export function getPostQueryKey(queryParams) {
  const queryObject = {};
  if (queryParams.currentBoard) {
    queryObject.currentBoard = queryParams.currentBoard.urlName;
  }
  if (typeof queryParams.boards !== 'undefined') {
    queryObject.boardURLNames = queryParams.boards;
  } else if (queryParams.board) {
    queryObject.boardURLNames = queryParams.board.urlName;
  }
  if (typeof queryParams.category !== 'undefined') {
    queryObject.categories = queryParams.category;
  }
  if (typeof queryParams.companies !== 'undefined') {
    queryObject.companyURLNames = queryParams.companies;
  }
  if (typeof queryParams.accountOwner !== 'undefined') {
    queryObject.accountOwnerName = queryParams.accountOwner;
  }
  if (typeof queryParams.categories !== 'undefined') {
    queryObject.categories = queryParams.categories;
  }
  if (typeof queryParams['post-created'] !== 'undefined') {
    queryObject['post-created'] = queryParams['post-created'];
  }
  if (typeof queryParams['vote-created'] !== 'undefined') {
    queryObject['vote-created'] = queryParams['vote-created'];
  }
  if (typeof queryParams['eta'] !== 'undefined') {
    queryObject['eta'] = queryParams['eta'];
  }
  if (typeof queryParams.filter !== 'undefined') {
    queryObject.filter = queryParams.filter;
  }
  if (typeof queryParams.owner !== 'undefined') {
    queryObject.owner = queryParams.owner;
  }
  if (typeof queryParams.query !== 'undefined') {
    queryObject.query = queryParams.query;
  }
  if (queryParams.scoreFactor) {
    queryObject.scoreFactor = queryParams.scoreFactor;
  }
  if (queryParams.segment) {
    queryObject.segment = queryParams.segment;
  }

  if (typeof queryParams.status !== 'undefined') {
    queryObject.status = queryParams.status;
  }
  if (typeof queryParams.tags !== 'undefined') {
    queryObject.tags = queryParams.tags;
  }
  if (queryParams.textSearch) {
    queryObject.textSearch = queryParams.textSearch;
  }
  if (queryParams.unassigned) {
    queryObject.unassigned = queryParams.unassigned;
  }
  if (queryParams.uncategorized) {
    queryObject.uncategorized = queryParams.uncategorized;
  }
  if (queryParams.untagged) {
    queryObject.untagged = queryParams.untagged;
  }

  if (queryParams.roadmap) {
    queryObject.roadmap = queryParams.roadmap._id;
  }

  if (!queryParams.roadmap) {
    queryObject.sort = queryParams.sort || (queryParams.textSearch ? 'relevance' : 'trending');
  }

  if (queryParams.descriptionColumnFilters) {
    queryObject.descriptionColumnFilters = queryParams.descriptionColumnFilters;
  }

  if (queryParams.factorFilters) {
    queryObject.factorFilters = queryParams.factorFilters;
  }

  return JSON.stringify(queryObject);
}
