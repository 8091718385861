import type { RevenueTimeframes } from 'app/js/company/RevenueHelpers';
import type { QueryParams as QueueQueryParams } from 'common/actions/queueItemQueries';
import type {
  ReviewPlatformNames,
  SyncIntegrationNames,
} from 'common/constants/autopilotIntegrations';
import type { Features, Integrations, Limits, Slidings } from 'common/constants/billing';
import type { ThirdPartyCompanyDefaultField } from 'common/thirdPartyCompanies/constants';

export type Member = {
  _id: string;
  avatarURL: string | null;
  created: string; // ISO 86001
  email: string;
  name: string;
  urlName: string;
  roleID: string;
};

type SalesforceSync = {
  _id: string;
  companyID: string;
  created: string; // ISO 86001
  deleted: boolean;
  syncAccounts: boolean;
  syncAccountFields: string[];
  syncContacts: boolean;
  syncContactDisallowNameOverride: boolean;
  syncContactFields: string[];
  syncContactOnlyExisting: boolean;
};

type HubspotSync = {
  _id: string;
  companyID: string;
  created: Date;
  deleted: boolean;
  hubspotID: string;
  lastAttempt: string; // ISO 86001
  lastSync?: string | null; // ISO 86001
  syncCompanies: boolean;
  syncCompanyFields: string[];
  syncContacts: boolean;
  syncContactFields: string[];
  syncing: boolean;
};

export type Role = {
  _id: string;
  name: string;
  permissions: {
    changePostETA: boolean;
    changePostOwner: boolean;
    changePostStatus: boolean;
    changePostTags: boolean;
    createPublicComment: boolean;
    customizeBoards: boolean;
    deleteComments: boolean;
    deleteInstance: boolean;
    deletePosts: boolean;
    deleteUsers: boolean;
    exportData: boolean;
    importPosts: boolean;
    manageAPIKey: boolean;
    manageAuthSettings: boolean;
    manageBilling: boolean;
    manageBoards: boolean;
    manageBoardPrivacy: boolean;
    manageCategories: boolean;
    manageChangelog: boolean;
    manageChangelogPrivacy: boolean;
    manageCompanyProfile: boolean;
    manageCustomDomains: boolean;
    manageCustomPostFields: boolean;
    manageEmailSettings: boolean;
    manageQueue: boolean;
    manageIntegrations: boolean;
    managePostAutomation: boolean;
    manageRoadmap: boolean;
    manageSSOKey: boolean;
    manageStatuses: boolean;
    manageTags: boolean;
    manageTeammates: boolean;
    manageUserSegmentation: boolean;
    manageWebhooks: boolean;
    mergePosts: boolean;
    pinComments: boolean;
    summarizeComments: boolean;
    viewUserDetails: boolean;
    voteOnBehalf: boolean;
  };
};
export type RolePermissionName = keyof Role['permissions'];

type CustomFieldAttribute = {
  customFieldID: string;
  op: string;
  value: unknown;
};

type StandardFieldAttribute = {
  op: string;
  standardFieldID: string;
  value: unknown;
};

export type Attribute = CustomFieldAttribute | StandardFieldAttribute;

export type Segment = {
  _id: string;
  attributes: Attribute[];
  companyID: string;
  created: Date;
  deleted: boolean;
  name: string;
  urlName: string;
};

type Plan = {
  features: Features;
  integrations: Integrations;
  limits: Limits;
  timeframe: string;
  planID: string | null;
  name: string | null;
};

export type PlanTrial = {
  planID: string;
};

type Stats = {
  adminCount: number;
  commentCount: number;
  firstPostDraftID: string | null;
  hasUsedIdentify: boolean;
  hasUsedSSO: boolean;
  onboardedAt: Date;
  opportunityCount: number;
  voteCount: number;
};

type UseCase = {
  completed: boolean | null;
  customerFeedback: boolean | null;
  feedbackSources?: string[];
  motivations?: string[];
  publicFeedback: boolean | null;
  teammateFeedback: boolean | null;
};

type Experiments = {
  [key: string]: string;
};

// Do not use the features that are enabled for the billing plan, disregarding any currently active trial
// You probably want to use "company.features" and "company.limits"
type FullBillingData = {
  activeUntil?: Date | null;
  cancelled: boolean;
  hasCard: boolean;
  invoiceEmail: string;
  isFree: boolean;
  last4: string | null;
  onboardingDue: Date;
  onboardingExtensions: number;
  plan: Plan;
  planStarted: Date;
  status: string | null;
  statusChanged: Date | null;
  stripeID: string | null;
  trialEnds: Date | null;
  // Do not use the below values; these represent the billing plan, disregarding any currently active trial
  // You probably want to use "company.features" and "company.limits" instead
  features: Features;
  integrations: Integrations;
  limits: Limits;
};

type StrippedBillingData = Pick<FullBillingData, 'activeUntil' | 'status' | 'isFree'> & {
  plan: never;
};

export type BillingData = FullBillingData | StrippedBillingData;

export enum StatusType {
  Initial = 'Initial',
  Active = 'Active',
  Complete = 'Complete',
  Closed = 'Closed',
}

// TODO: Incomplete, extend this as needed
export type CompanyPostStatus = {
  _id: string;
  color: string;
  name: string;
  type: StatusType;
  urlName: string;
};

type QueueAutomationSettings = {
  authorID: string;
  boardID: string;
  editedBy: string;
  enabled: boolean;
};

type CompanySettings = {
  queueAutomation: QueueAutomationSettings | null;
  deduplication: {
    editedBy: string;
    enabled: boolean;
  } | null;
  enableEmailAuth: boolean;
  enableGlobalPostSearch: boolean;
  revenueTimeframe: RevenueTimeframes;
  revenueSyncTimeframe: RevenueTimeframes;
  secureIdentify: boolean;
};

type Nux = {
  adminOnboarding: boolean;
  autopilot: boolean;
  boardIntegration: boolean;
  boardPrivacy: boolean;
  boardOnboarding: boolean;
  bookDemo: boolean;
  inviteTeammates: boolean;
  installIntegration: boolean;
  prioritizationOverview: boolean;
  widgetOverview: boolean;
  voteBehalf: boolean;
};

type OIDCConfig = {
  clientID: string;
  defaultAccessLevel: string;
  discoveryDocURL: string;
};

export type CompanyTeam = {
  _id: string;
  avatarEmoji: string;
  name: string;
  members: CompanyTeamMember[];
};

export type CompanyTeamMember = {
  userID: string;
  avatarURL: string | null;
  email: string | null;
  name: string;
};

export type ViewerPreferences = {
  lastViewedRoadmap: string;
  queuePostFilters?: QueueQueryParams;
  queueFilters?: QueueQueryParams;
  lastActiveFilters: {
    filterQuery: string;
    savedFilter: string;
  };
};

export type Filter = {
  _id: string;
  name: string;
};

export type CustomField = {
  _id: string;
  companyID: string;
  created: Date;
  fieldType: 'boolean' | 'number' | 'date' | 'string' | null;
  name: string;
  objectType: 'user' | 'company';
  prettyName: string;
};

type SyncIntegration = {
  disabled: boolean;
  disabledReason: 'outOfCredits' | 'user' | 'uninstalled' | 'lostAccess' | null;
  integrationName: SyncIntegrationNames;
};

export type SyncReviewIntegration = {
  countryCode: string | null;
  platform: ReviewPlatformNames;
  profileName: string;
  url: string;
};

type Autopilot = {
  additionalCreditLimit: number;
  additionalCreditsDisabled: boolean;
  creditGiftRedeemed: boolean;
  creditLimit: number;
  creditsPerPurchase: number;
  planLimit: number;
  unlimitedAdditionalCredits: boolean;
  usedCredits: number;
};

type Intercom = {
  appID: string;
  autopilotTeamInboxIDs: string[] | null;
  name: string;
  lostAccess: boolean;
};

type Zendesk = {
  lostAccess: boolean;
  name: string;
  subdomain: string;
};

export type MicrosoftTeamsChannel = {
  _id: string;
  azureTenantID: string;
  boardIDs: string[];
  channelID: string;
  channelName: string | null;
  companyID: string;
  created: string; // ISO 86001
  createdBy: string | null;
  deleted: boolean;
  deletedBy: string | null;
  deletedDate: string | null;
  groupID: string | null;
  notificationTypes: {
    newComments: boolean;
    newEntries: boolean;
    newPosts: boolean;
    newVotes: boolean;
    statusChanges: boolean;
    voteMilestones: boolean;
  };
  registeredByTeamUserID: string;
  teamID: string | null;
  teamName: string | null;
  webhookURL: string;
};

// TODO: Incomplete. Extend this as props are used and needed
type Changelog = {
  private: boolean;
};

type AutoResponse = {
  authorID: string;
};

// TODO: Incomplete. Extend this as props are used and needed
// /api/company/get
export type Company = {
  _id: string;
  activeIntegrations: Integrations;
  authRedirectEnabled: boolean;
  authRedirectURL: string;
  autopilot: Autopilot;
  autoResponse?: AutoResponse;
  billingData?: BillingData;
  boardCount: number;
  changelog: Changelog;
  contributorCount: number;
  customFields: {
    company: CustomField[];
    user: CustomField[];
  };
  hasCollectedMonthlySpend: boolean;
  hasClearbitData: boolean;
  experiments: Experiments;
  faviconURL: string | null;
  features: Features;
  featureAllowlist: string[];
  hasUnlistedBoard: boolean;
  hubspotSync: HubspotSync | null;
  installedSyncIntegrations: SyncIntegration[];
  installedSyncReviewIntegrations: SyncReviewIntegration[];
  integrationCount: number;
  integrations?: Integrations;
  intercom: Intercom | null;
  inviteCount: number;
  limits: Plan['limits'];
  loading?: never;
  logoURL: string | null;
  members: Member[];
  microsoftTeamsChannels: MicrosoftTeamsChannel[];
  name: string;
  nux: Nux;
  postCount: number;
  revenueTimeframe: RevenueTimeframes;
  roadmapBoards?: string[];
  roles: Role[];
  salesforceSync: SalesforceSync | null;
  savedFilters: Filter[];
  savedViews: Filter[];
  segments: Segment[];
  showRoadmap: boolean;
  sliding: Slidings;
  stats: Stats;
  statuses: CompanyPostStatus[];
  subdomain: string;
  thirdPartyCompanyFieldMapping: Record<ThirdPartyCompanyDefaultField, string>;
  tintColor: string;
  trialingPlan: PlanTrial | null;
  updatedAt: string | null; // ISO 8601
  updatedBy: string | null; // admin's user id
  useCase: UseCase;
  userCount: number;
  oidc: OIDCConfig | null;
  viewerPreferences: ViewerPreferences;
  viewerIsMember: boolean;
  zendesk: Zendesk | null;
} & CompanySettings;
