import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import CopyButton from 'common/inputs/CopyButton';
import TextInput from 'common/inputs/TextInput';
import Message from 'common/message/Message';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import Alert, { AlertTypes } from 'common/ui/Alert';
import devURL from 'common/util/devURL';
import getHostname from 'common/util/getHostname';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminOneLoginSettings.scss';

const FakePlaceholderSecret = '••••••••••••••••••••••••••••••••••••';
const HelpGuideLink = 'https://help.canny.io/en/articles/5087863-onelogin-sso-integration';
const MessageOrigin = devURL('https://canny.io');

class AdminOneLoginSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      scim: PropTypes.object,
      oneLogin: PropTypes.shape({
        oneLoginDomain: PropTypes.string,
        clientID: PropTypes.string,
      }),
    }),
    openModal: PropTypes.func,
    reloadCompany: PropTypes.func,
  };

  state = {
    clientID: this.props.company.oneLogin?.clientID ?? '',
    clientSecret: this.props.company.oneLogin ? FakePlaceholderSecret : '',
    error: null,
    hasMadeChanges: false,
    isSCIMInstalled: this.props.company.scim?.identityProvider === 'onelogin',
    isSSOInstalled: !!this.props.company.oneLogin,
    loadingConnectSCIM: false,
    loadingConnectSSO: false,
    loadingDisconnectSCIM: false,
    loadingDisconnectSSO: false,
    oneLoginDomain: this.props.company?.oneLogin?.oneLoginDomain ?? '',
    scimBearerToken: this.props.company.scim ? FakePlaceholderSecret : '',
  };

  constructor(props, context) {
    super(props, context);
    this.clientSecretRef = React.createRef();
  }

  checkClientSecret = () => {
    // Clear the placeholder secret if the config was modified
    const shouldClear = this.state.clientSecret === FakePlaceholderSecret;

    if (shouldClear) {
      this.setState({ clientSecret: '' }, () => {
        this.clientSecretRef.current.setValue('');
      });
    }
  };

  doesPlanSupport() {
    const { company } = this.props;
    return company?.integrations?.oneLogin;
  }

  onClientIDChange = (e) => {
    this.setState({
      error: null,
      hasMadeChanges: true,
      clientID: e.target.value,
    });
    this.checkClientSecret();
  };

  onClientSecretChange = (e) => {
    this.setState({
      error: null,
      hasMadeChanges: true,
      clientSecret: e.target.value,
    });
  };

  onConnectSCIM = async () => {
    if (this.state.loadingConnectSCIM) {
      return;
    }
    this.setState({ loadingConnectSCIM: true });
    const responseJSON = await AJAX.post('/api/scim/register', { identityProvider: 'onelogin' });
    try {
      const response = JSON.parse(responseJSON);
      if (response.bearerToken) {
        this.setState({
          isSCIMInstalled: true,
          loadingConnectSCIM: false,
          scimBearerToken: response.bearerToken,
        });
      } else {
        this.setState({
          loadingConnectSCIM: false,
          error: 'Something went wrong, please contact our team for support.',
        });
      }
    } catch (e) {
      this.setState({
        loadingConnectSCIM: false,
        error: 'Something went wrong, please contact our team for support.',
      });
    }
    this.props.reloadCompany();
  };

  onConnectSSO = async () => {
    if (this.state.loadingConnectSSO) {
      return;
    }
    this.setState({ loadingConnectSSO: true });

    const { clientID, clientSecret, oneLoginDomain } = this.state;
    const response = await AJAX.post('/api/oneLogin/register', {
      clientID,
      clientSecret,
      oneLoginDomain,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'invalid oneLoginDomain or clientID': 'Either the OneLogin Domain or Client ID is invalid.',
        'invalid oneLoginDomain': 'Please enter a valid OneLogin Domain.',
        default: 'Something went wrong, please contact our team for support.',
      },
    });

    if (error) {
      this.setState({
        loadingConnectSSO: false,
        error,
      });
      return;
    }

    this.startValidation();
  };

  onDisconnectSCIM = async () => {
    if (this.state.loadingDisconnectSCIM) {
      return;
    }
    this.props.openModal(ConfirmModal, {
      message: `Are you sure you'd like to disconnect your OneLogin organization? This means users will no longer be automatically provisioned or deprovisioned.`,
      onConfirm: async () => {
        this.setState({ loadingDisconnectSCIM: true });
        const response = await AJAX.post('/api/scim/disconnect', {});
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please contact our team for support.',
            loadingDisconnectSCIM: false,
          });
          return;
        }
        this.setState({ isSCIMInstalled: false, loadingDisconnectSCIM: false });
        this.props.reloadCompany();
      },
    });
  };

  onDisconnectSSO = () => {
    const { openModal } = this.props;
    if (this.state.loadingDisconnectSSO) {
      return;
    }

    openModal(ConfirmModal, {
      message: `Are you sure you'd like to disconnect your OneLogin organization? This means your users will not be able to SSO into Canny from OneLogin.`,
      onConfirm: async () => {
        this.setState({ loadingDisconnectSSO: true });
        const response = await AJAX.post('/api/oneLogin/disconnect', {});

        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please contact our team for support.',
            loadingDisconnectSSO: false,
          });
          return;
        }

        this.setState({ isSSOInstalled: false, loadingDisconnectSSO: false });
      },
    });
  };

  onDomainChange = (e) => {
    this.setState({
      error: null,
      hasMadeChanges: true,
      oneLoginDomain: e.target.value,
    });
    this.checkClientSecret();
  };

  startValidation() {
    const oneLoginHostname = getHostname(this.state.oneLoginDomain);
    const initiateURL = devURL(
      `https://canny.io/api/oneLogin/initiate?verifyDomain=https%3A%2F%2F${oneLoginHostname}`
    );
    // No, 'noopener' because OneLogin is a well-known provider
    const oneLoginWindow = window.open(initiateURL, '_blank');

    const unsubscribe = Message.subscribe(
      oneLoginWindow,
      MessageOrigin,
      'oneLoginValidation',
      (result) => {
        unsubscribe();
        let error = null;

        if (result.validation === 'invalid') {
          error = 'The Client Secret seems to be invalid.';
        } else if (result.validation === 'error') {
          error = 'An unknown error occurred.';
        }

        this.setState({
          loadingConnectSSO: false,
          isSSOInstalled: true,
          error,
          hasMadeChanges: error ? true : false,
        });
      }
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }
    return <div className="error">{this.state.error}</div>;
  }

  renderProvisioningInfo() {
    if (!this.doesPlanSupport()) {
      return null;
    }
    return (
      <div className="infoSection">
        <div className="subheading">
          Automatically provision and deprovision your teammates with SCIM
        </div>
        <p>
          By configuring SCIM, you can create, update, and deactivate your Canny teammates via
          OneLogin's admin portal. Check out our{' '}
          <a
            className="link"
            href={HelpGuideLink}
            target="_blank"
            rel="nofollow noreferrer noopener">
            setup guide
          </a>{' '}
          for more info.
        </p>
      </div>
    );
  }

  renderSCIMConfig() {
    if (!this.doesPlanSupport()) {
      return null;
    }
    const { isSCIMInstalled, loadingConnectSCIM, loadingDisconnectSCIM, scimBearerToken } =
      this.state;

    return (
      <div className="settingsSection">
        {isSCIMInstalled && (
          <div className="config">
            <p className="configHeading">SCIM Bearer Token (Secret)</p>
            <div className="configValue">
              <p>{scimBearerToken}</p>
              {scimBearerToken !== FakePlaceholderSecret && <CopyButton value={scimBearerToken} />}
            </div>
          </div>
        )}
        <div className="buttons">
          {!isSCIMInstalled && (
            <Button
              onTap={this.onConnectSCIM}
              loading={loadingConnectSCIM}
              value="Generate SCIM Token"
            />
          )}
          {isSCIMInstalled && (
            <Button
              onTap={this.onDisconnectSCIM}
              loading={loadingDisconnectSCIM}
              value="Disconnect SCIM"
            />
          )}
        </div>
      </div>
    );
  }

  renderSSOConfig() {
    const {
      clientID,
      clientSecret,
      hasMadeChanges,
      oneLoginDomain,
      loadingConnectSSO,
      loadingDisconnectSSO,
      isSSOInstalled,
    } = this.state;
    if (!this.doesPlanSupport() && !isSSOInstalled) {
      return null;
    }

    const canSubmit =
      oneLoginDomain && clientID && clientSecret && !loadingDisconnectSSO && hasMadeChanges;

    return (
      <Form
        addEventsToDocument={false}
        disableSubmit={!canSubmit || loadingConnectSSO}
        onSubmit={this.onConnectSSO}>
        <div className="ssoConfig">
          <p className="instructions">
            Enter your OneLogin SSO details to complete the installation of our integration. Check
            out our{' '}
            <a
              className="link"
              href={HelpGuideLink}
              target="_blank"
              rel="nofollow noreferrer noopener">
              setup guide
            </a>{' '}
            for more info.
          </p>
          <TextInput
            inset="OneLogin Domain"
            defaultValue={oneLoginDomain}
            onChange={this.onDomainChange}
            prefix="https://"
            placeholder="your-auth-server.onelogin.com"
          />
          <TextInput
            inset="App Client ID"
            defaultValue={clientID}
            onChange={this.onClientIDChange}
          />
          <TextInput
            ref={this.clientSecretRef}
            inset="App Client Secret"
            defaultValue={clientSecret}
            onFocus={this.checkClientSecret}
            onChange={this.onClientSecretChange}
          />
        </div>
        <Alert
          type={AlertTypes.Danger}
          headingText="Billing alert"
          subText={`Some roles are billed per-admin and will incur additional charges.`}
        />
        <div className="buttons">
          <Button
            disabled={!canSubmit}
            formButton={true}
            loading={loadingConnectSSO}
            value={isSSOInstalled ? 'Update' : 'Connect OneLogin'}
          />
          {isSSOInstalled && (
            <Button
              onTap={this.onDisconnectSSO}
              loading={loadingDisconnectSSO}
              value="Disconnect"
            />
          )}
        </div>
      </Form>
    );
  }

  renderSSOInfo() {
    if (!this.doesPlanSupport()) {
      return null;
    }
    return (
      <div className="infoSection">
        <div className="subheading">Authenticate your teammates via SSO</div>
        <p>
          Grant your teammates access to Canny so they can sign in via SSO with their OneLogin
          account.
        </p>
      </div>
    );
  }

  renderUpsell() {
    const { isSSOInstalled } = this.state;
    if (this.doesPlanSupport() || isSSOInstalled) {
      return null;
    }

    return (
      <AdminFeatureBlock
        feature="OneLogin Integration"
        benefit="Automatically provision and deprovision your teammates within Canny. Teammates can log in via OneLogin Single Sign-On."
        showBillingLink={false}
      />
    );
  }

  render() {
    return (
      <div className="adminOneLoginSettings">
        <Helmet title="OneLogin Integration | Canny" />
        <div className="settingsHeading">OneLogin Integration</div>
        <div className="content">
          {this.renderProvisioningInfo()}
          {this.renderSCIMConfig()}
          {this.renderSSOInfo()}
          {this.renderSSOConfig()}
          {this.renderError()}
          {this.renderUpsell()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.integrations.oneLogin),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    { forwardRef: true }
  )
)(AdminOneLoginSettings);
