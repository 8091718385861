import React, { type FC, useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import Alert, { AlertTypes } from 'common/ui/Alert';
import SwitchV2 from 'common/ui/SwitchV2';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/AdminGeneralSettings/_AdminSecuritySettings.module.scss';

import type { Company } from 'common/api/endpoints/companies';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

const AdminSecureIdentifySettings: FC<Props> = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  const [secureIdentify, setSecureIdentify] = useState<boolean>(company.secureIdentify);
  const [loading, setLoading] = useState<boolean>(false);

  const savePreferences = async () => {
    setLoading(true);

    const response = await AJAX.post('/api/company/changeSettings', {
      secureIdentify,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      setLoading(false);
      return;
    }

    await reloadCompany();
    setLoading(false);
  };

  return (
    <div className={styles.adminSecuritySettings}>
      <Helmet title="Security | General settings | Canny" />
      <H2 variant="bodyMd" fontWeight="bold" className={styles.heading}>
        Secure Canny Identify
      </H2>
      <P className={styles.content}>
        Keep your organization's accounts safe with a unique code, preventing impersonation and
        unauthorized access to sensitive data. This is especially important if you use{' '}
        <a
          className={styles.linkOut}
          target="_blank"
          rel="nofollow noopener"
          href="https://help.canny.io/en/articles/9829559-custom-access-boards">
          custom access boards
        </a>
        .
      </P>
      <P className={styles.content}>
        <a
          className={styles.linkOut}
          href="https://help.canny.io/en/articles/10036470-secure-identify-requests-to-canny-sdk"
          target="_blank"
          rel="nofollow noopener">
          Learn more about why securing identify matters
        </a>
      </P>
      <P className={styles.content}>
        <a
          className={styles.linkOut}
          href="https://developers.canny.io/install/security"
          target="_blank"
          rel="nofollow noopener">
          Learn how to secure Identify
        </a>
      </P>
      <div className={styles.toggleContainer}>
        <P>Require user-specific hash when identifying users through the Canny SDK</P>
        <SwitchV2
          onChange={() => setSecureIdentify((secureIdentify) => !secureIdentify)}
          checked={secureIdentify}
        />
      </div>
      {secureIdentify !== company.secureIdentify && secureIdentify && (
        <Alert
          type={AlertTypes.Danger}
          headingText="Warning!"
          subText="To avoid errors, please confirm that you've updated the Canny snippet in your application before enabling this feature."
        />
      )}
      <Button
        buttonType="cannyButton"
        className="saveButton"
        disabled={secureIdentify === company.secureIdentify}
        loading={loading}
        onTap={savePreferences}
        value="Save"
      />
    </div>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.security['secure-identify']),
    '/admin/settings'
  )
)(AdminSecureIdentifySettings) as unknown as React.FC<OwnProps>;
